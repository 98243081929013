import ExporterSchema from '../../../../../modules/shared/exporter/ExporterSchema';
import { Excel } from '../../../../../modules/shared/excel';
import { getSubThemeTitle } from '../../../../../services/subThemes';
import { getDocumentTypeTitleFromTypeId } from '../../../../../services/documents';
import { getProviderNameFromId } from '../../../../../services/ThirdParties';
import { getMatterNameById } from '../../../../../services/matter';

export default class Exporter {
  constructor(fields, excelFileName, subThemes, documentTypes, thirdParties, providerMatters) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
    this.subThemes = subThemes;
    this.documentTypes = documentTypes;
    this.thirdParties = thirdParties;
    this.matters = providerMatters;
  }

  formatHeaders =(row) => ({
    id: row.id,
    Date: row.humanVersionDate,
    Titre: row.title,
    Fournisseur: getProviderNameFromId(row.providerId, this.thirdParties),
    Sous_theme: getSubThemeTitle(this.subThemes, row.themeId),
    type: getDocumentTypeTitleFromTypeId(this.documentTypes, row.type),
    Lien: row.downloadUrl,
    Matière: getMatterNameById(row.matterId, this.matters, currenMatter => currenMatter.id,
      currenMatter => currenMatter.name),
    Valide: row.validity ? 'Oui' : 'Non',
  });

  transformAndExportAsExcelFile(rows) {
    const exportableData = rows.map((row) => this.formatHeaders(row)
    );

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      `${this.excelFileName} ${new Date().getTime()}`,
    );
  }
}
