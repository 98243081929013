import { fromJS, List } from 'immutable';
import Themes from './constants';

const initialState = fromJS({
  loading: false,
  themes: [],
  message: '',
  detail: '',
  deleting: '',
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case Themes.FETCH_LOADING:
      return state.set('loading', true);
    case Themes.FETCH_SUCCESS: {
      const themes = action.response || [];
      return state
        .set('themes', List(themes))
        .set('loading', false);
    }
    case Themes.FETCH_ERROR:
      return state
        .set('loading', false)
        .set('message', 'erreur');
    case Themes.ADD_THEME_SUCCESS:
      return state
        .set('message', 'success');
    case Themes.THEME_DETAIL_REQUEST:
      return state.set('detail', action.id);
    case Themes.THEME_DETAIL_SUCCESS: {
      // if (index < 0) {
      //   return state.set('themes', fromJS([action.theme, ...themes]));
      // }
      //
      // return state.set(
      //   'themes',
      //   fromJS(
      //     themes.map(item => (item.id === action.theme.id ? action.theme : item))
      //   )
      // );
      return state;
    }
    default:
      return state;
  }
}

export default appReducer;
