const getCustomerId = (props) => {
  const { profile: { role }, match: { params: { customerId } } } = props;
  if (props.profile.role !== 'superAdmin') {
    return props.profile;
  }
  return { customerId, role };
};

const getUserUsernameAndPasswordFromId = (usersList, id) => {
  const pilot = usersList.find(user => user.id === id);
  return pilot ? `${pilot?.username} - ${pilot?.email}` : '';
};

const getUserMailFromId = (usersList, id) => {
  const pilot = usersList.find(user => user.id === id);
  return pilot?.email;
};

const getUserSelectOptions = (usersList) => usersList.map(user => ({ key: user.id, label: user.username, title: user.username })
);

const getUserSelectOptionsForFilter = (usersList) => usersList.map(
  user => ({ key: user.id, value: user.id, title: user.username })
);

const getUsernameFromId = (usersList, id) => {
  if (!id) return null;
  const u = usersList.find(user => user.id === id);
  if (!u) return '';
  return u?.username;
};

export {
  getCustomerId,
  getUserUsernameAndPasswordFromId,
  getUserSelectOptions,
  getUserMailFromId,
  getUserSelectOptionsForFilter,
  getUsernameFromId
};
