import { createStore, applyMiddleware, compose } from 'redux';
import LogRocket from 'logrocket';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';
import { call, all, spawn } from 'redux-saga/effects';
// eslint-disable-next-line no-unused-vars
import firebase from '../firebase';


import createReducer from '../reducers';
import { ThemesSaga } from '../containers/admin/themes/saga';
import { SubThemesSaga } from '../containers/admin/subThemes/sagas';
import { FormSaga } from '../containers/admin/form/sagas';
import { CustomerSaga } from '../containers/admin/customers/saga';
import { LoginSaga } from '../containers/login/sagas';
import { UserSaga } from '../containers/admin/users/sagas';
import { ActionSaga } from '../containers/companies/actions/sagas';
import { DocumentSaga } from '../containers/companies/documents/sagas';
import { ActionPlanSaga } from '../containers/companies/plans/sagas';
import { AccountSaga } from '../containers/account/sagas';
import { SubscriptionSaga } from '../containers/companies/themesSubscription/sagas';
import { CompanyFormSaga } from '../containers/companies/form/sagas';
import { OriginSaga } from '../containers/admin/origin/sagas';
import { NonComformitySaga } from '../containers/companies/nonConformities/sagas';
import { AlertsSaga } from '../containers/companies/alerts/sagas';
import { TypeSaga } from '../containers/admin/types/sagas';
import { LibrarySaga } from '../containers/companies/library/sagas';
import { BroadcastGroupsSaga } from '../containers/companies/broadcastGroups/sagas';
import { ThirdPartiesSaga } from '../containers/companies/thirdParties/sagas';
import { AtmosphereSaga } from '../containers/companies/atmosphere/sagas';

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line no-undef,no-unused-vars,no-underscore-dangle
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

function* rootSaga() {
  const sagas = [
    ThemesSaga,
    SubThemesSaga,
    FormSaga,
    CustomerSaga,
    LoginSaga,
    UserSaga,
    ActionSaga,
    DocumentSaga,
    ActionPlanSaga,
    AccountSaga,
    SubscriptionSaga,
    CompanyFormSaga,
    OriginSaga,
    NonComformitySaga,
    AlertsSaga,
    TypeSaga,
    LibrarySaga,
    BroadcastGroupsSaga,
    ThirdPartiesSaga,
    AtmosphereSaga,
  ];

  yield all(sagas.map(saga => spawn(function* generator() {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        console.log(e);
      }
    }
  }))
  );
}

const configureStore = (initialState = {}, history) => {
  const middlewares = [sagaMiddleware, routerMiddleware(history), LogRocket.reduxMiddleware()];

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    compose(
      applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
