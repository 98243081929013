const ThirdParties = {
  THIRD_PARTIES_ADDING: 'thirdParties/add',
  THIRD_PARTIES_UPDATE: 'thirdParties/update',
  THIRD_PARTIES_DELETE: 'thirdParties/delete',
  THIRD_PARTIES_ACTIVATE_STATUS: 'thirdParties/statusUpdate',
  THIRD_PARTIES_STATUS_UPDATE: 'broadcastGroups/statusUpdate',
  THIRD_PARTIES_DOCUMENT_UPLOAD: 'thirdParties/documentUpload',
  THIRD_PARTIES_DOCUMENT_UPDATE: 'thirdParties/documentUpdate',
  THIRD_PARTIES_MATTER_ADDED: 'thirdParties/matterAdded',
  THIRD_PARTIES_MATTER_UPDATE: 'thirdParties/matterUpdate',
  THIRD_PARTIES_MATTER_DELETE: 'thirdParties/matterDelete',
  THIRD_PARTIES_EXPORT_DOCUMENTS: 'thirdParties/exportDocument',
  THIRD_PARTIES_ADD_EXISTING: 'thirdParties/addExisting',
  THIRD_PARTIES_DELETE_DOCUMENT: 'thirdParties/deleteDocument',
  THIRD_PARTIES_ENHANCE_VALIDITY: 'thirdParties/enhanceValidity',
  THIRD_PARTIES_ADD_EXISTING_MATTER: 'thirdParties/addExistingMatter',
};

export default ThirdParties;
