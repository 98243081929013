import ExporterSchema from './ExporterSchema';
import { Excel } from '../../../../modules/shared/excel';
import { getSubThemeTitle } from '../../../../services/subThemes';

export default class Exporter {
  constructor(fields, excelFileName, subThemes) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
    this.subThemes = subThemes;
  }

  formatHeaders =(row) => ({
    id: row.id,
    Date: row.date,
    Titre: row.title,
    Commentaires: row.comments,
    Status: row.status ? 'Terminé' : 'En cours',
    Sous_theme: getSubThemeTitle(this.subThemes, row.subTheme),
    Plan_titre: row.a_title,
    Plan_commentaires: row.a_comments,
    Plan_date_de_creation: row.a_date,
    Plan_date_d_echeance: row.a_dueDate,
    Plan_status: row.a_status ? 'Terminé' : 'En cours',
  });

  transformAndExportAsExcelFile(rows) {
    const exportableData = rows.map((row) => this.formatHeaders(row)
    );

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      `${this.excelFileName} ${new Date().getTime()}`,
    );
  }
}
