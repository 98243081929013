import { fromJS } from 'immutable';

import Documents from './constants';

const initialState = fromJS({
  loading: false,
  progress: 0,
});

function DocumentReducer(state = initialState, action) {
  switch (action.type) {
    case Documents.DOCUMENT_UPLOADING:
      return state
        .set('loading', true)
        .set('progress', action.payload);
    case Documents.DOCUMENT_UPLOADED:
      return state.set('loading', false);
    case Documents.DOCUMENT_PROVIDER_UPLOADING:
      return state
        .set('loading', true)
        .set('progress', action.payload);
    case Documents.DOCUMENT_PROVIDER_UPLOADED:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default DocumentReducer;
