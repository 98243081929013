import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import User from './constants';

const BASE_URL = 'https://us-central1-axelcontrol-98dd0.cloudfunctions.net';

function* onUserAdding(action) {
  const {
    cb, customerId, props
  } = action;
  const url = `${BASE_URL}/createUser`;
  try {
    const { username, email } = action.item;
    const role = action.item.role || action.role;
    const company = action.item.company || 'Interne';
    const broadcastGroups = action.item.broadcastGroups || null;
    const status = true;
    const idToken = yield props.firebase.auth().currentUser.getIdToken(true);
    // eslint-disable-next-line no-undef
    yield fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        email, username, customerId, role, company, status, broadcastGroups
      },),
    });
    props.dispatch({
      type: User.USER_ADDED,
    });
    notification.success({
      message: 'Utilisateur ajouté avec succès',
      description: 'L\'utilisateur sera disponible d\'ici quelques secondes',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter l\'utilisateur',
      description: err.toString(),
    });
    props.dispatch({
      type: User.USER_ADDED,
    });
  }
}

function* onUserStatusUpdate(action) {
  try {
    const { status, id, props } = action;
    const docRef = yield props.firestore.collection('customers').doc(id);

    yield docRef.set({
      status,
    }, { merge: true });
    notification.success({
      message: 'Status mis à jour avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour le status',
      description: err.toString(),
    });
  }
}

function* onUserDelete(action) {
  const url = `${BASE_URL}/deleteUser`;
  try {
    const { email, props } = action;
    const idToken = yield props.firebase.auth().currentUser.getIdToken(true);
    // eslint-disable-next-line no-undef
    yield fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ email }),
    });
    notification.success({
      message: 'Utilisateur supprimer avec succès',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer l\'utilisateur',
      description: err.toString(),
    });
  }
}

function* onAdminUpdateUserProfile(action) {
  // return console.table(action);
  const url = `${BASE_URL}/updateUser`;
  try {
    const { item, props, cb } = action;
    const { id, username, email } = item;
    const idToken = yield props.firebase.auth().currentUser.getIdToken(true);
    // eslint-disable-next-line no-undef
    yield fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ id, username, email }),
    });
    notification.success({
      message: 'Utilisateur modifier avec succès',
      description: '',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier l\'utilisateur',
      description: err.toString(),
    });
  }
}

function* onUpdateUserProfile(action) {
  try {
    const { username } = action.item;
    const { cb, props } = action;
    yield props.firebase.updateProfile({ username });
    notification.success({
      message: 'Utilisateur modifié avec succès',
      description: '',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier l\'utilisateur',
      description: err.toString(),
    });
  }
}

function* onUpdateUserEmail(action) {
  try {
    const { email } = action.item;
    const { cb, props } = action;
    yield props.firebase.updateEmail(email);
    notification.success({
      message: 'Email utilisateur modifié avec succès',
      description: '',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier l\'email de l\'utilisateur',
      description: err.toString(),
    });
  }
}

function* onUpdateCustomer(action) {
  try {
    const {
      id, item, props: { firestore }, cb
    } = action;
    const { username, role, customerId } = item;
    const company = item.company || null;
    const broadcastGroups = item.broadcastGroups || null;
    if (id !== customerId) {
      notification.error({
        message: 'Impossible de mettre à jour l\'utilisateur',
      });
    } else {
      const docRef = yield firestore.collection('users').doc(item.id);
      yield docRef.set({
        username,
        role,
        broadcastGroups,
        company,
      }, { merge: true });
      notification.success({
        message: 'Utilisateur mis à jour avec succès',
      });
    }
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour l\'utilisateur',
      description: err.toString(),
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* UserSaga() {
  yield takeLatest(User.ADD_USER, onUserAdding);
  yield takeLatest(User.UPDATE_USER_PROFILE, onUpdateUserProfile);
  yield takeLatest(User.UPDATE_USER_EMAIL, onUpdateUserEmail);
  yield takeLatest(User.DELETE_USER, onUserDelete);
  yield takeLatest(User.USER_UPDATE_STATUS, onUserStatusUpdate);
  yield takeLatest(User.USER_CUSTOMER_UPDATE, onUpdateCustomer);
  yield takeLatest(User.USER_UPDATE_PROFILE_BY_ADMIN, onAdminUpdateUserProfile);
}
