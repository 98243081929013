import { fromJS } from 'immutable';

import User from './constants';

const initialState = fromJS({
  loading: false,
});

function UserReducer(state = initialState, action) {
  switch (action.type) {
    case User.ADD_USER:
      return state.set('loading', true);
    case User.USER_ADDED:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default UserReducer;
