import {
  takeLatest
} from 'redux-saga/effects';
import { notification } from 'antd';
import _ from 'lodash';
import Exporter from './export/Exporter';
import ExporterFields from './export/ExporterFields';
import Library from './constant';

function* exportDocuments(action) {
  try {
    const { selectedRows, props } = action;
    const {
      documents, subThemes, documentTypes, actions, actionPlans
    } = props;

    const documentsToExport = documents.filter(doc => selectedRows.includes(doc.id));
    const docWithActionAndActionplan = documentsToExport.map(item => ({
      item,
      actionItem: actions.filter(actionItem => actionItem.id === item.actionId),
      actionPlanItem: actionPlans.filter(planItem => planItem.id === item.actionPlanId)
    }));

    const finalValues = [];
    docWithActionAndActionplan.forEach(obj => {
      finalValues.push({
        ...obj.item,
      });
      const { actionItem, actionPlanItem } = obj;
      if (!_.isEmpty(actionItem)) {
        actionItem.forEach(actionChild => {
          finalValues.push({
            ...obj.item,
            a_title: actionChild.title,
            a_date: actionChild.date,
            a_status: actionChild.status,
          });
        });
      }
      if (!_.isEmpty(actionPlanItem)) {
        actionPlanItem.forEach(planChild => {
          finalValues.push({
            ...obj.item,
            p_title: planChild.title,
            p_comments: planChild.comments,
            p_date: planChild.date,
            p_dueDate: planChild.dueDate,
            p_status: planChild.status,
          });
        });
      }
    });
    yield new Exporter(
      ExporterFields,
      'Liste des documents',
      subThemes,
      documentTypes,
    ).transformAndExportAsExcelFile(finalValues);
    notification.success({
      message: 'Export complété avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de procéder à l\'export',
      description: err.toString(),
    });
  }
}

export function* LibrarySaga() {
  yield takeLatest(Library.LIBRARY_DO_EXPORT, exportDocuments);
}
