import { fromJS } from 'immutable';

import App from './constants';

const initialState = fromJS({
  loading: false,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case App.APP_LOADED: {
      return state.set('loading', true);
    }
    default:
      return state;
  }
}

export default appReducer;
