import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import BroadcastGroups from './constants';

/**
 * Create a broadcast group
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* createBroadcastGroup(action) {
  const {
    item, props, customerId, cb
  } = action;
  const description = item?.description || null;
  const { subThemes, title } = item;

  try {
    const docRef = yield (props.firestore.collection('customers')
      .doc(customerId)
      .collection('broadcastGroups').doc());
    yield docRef.set({
      title,
      subThemes,
      description,
      id: docRef.id,
      status: true,
    });
    notification.success({
      message: 'Groupe de diffusion ajouté avec succès',
      description: `${title} est créé`,
    });
    cb && cb(docRef.id);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le groupe de diffusion',
      description: err.toString(),
    });
  }
}

/**
 * Update a broadcast group
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* updateBroadcastGroup(action) {
  try {
    const {
      item, props, cb, id
    } = action;
    const description = item?.description || null;
    const { subThemes, title } = item;
    const { profile: { customerId }, firestore } = props;

    const docRef = yield firestore.collection('customers')
      .doc(customerId)
      .collection('broadcastGroups').doc(id);

    yield docRef.set({
      title,
      subThemes,
      description,
    }, { merge: true });

    notification.success({
      message: 'Groupe de diffusion mis à jour avec succès',
      description: `${title} est créé`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre le groupe de diffusion',
      description: err.toString(),
    });
  }
}

/**
 * Update a broadcast group status
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* updateBroadcastGroupStatus(action) {
  try {
    const {
      status, id, props: { firestore, profile: { customerId } }
    } = action;
    const docRef = yield firestore.collection('customers')
      .doc(customerId)
      .collection('broadcastGroups').doc(id);

    yield docRef.set({
      status,
    }, { merge: true });
    notification.success({
      message: 'Status mis à jour avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour le status',
      description: err.toString(),
    });
  }
}

/**
 * Delete a broadcast group
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* deleteBroadcastGroup(action) {
  try {
    const { id, props: { profile: { customerId }, firestore } } = action;
    yield firestore.collection('customers')
      .doc(customerId)
      .collection('broadcastGroups')
      .doc(id)
      .delete();
    notification.success({
      message: 'Groupe de diffusion supprimé avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer le gropue de diffusion',
      description: err.toString(),
    });
  }
}

/**
 * Update broadcasts group status
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* updateBroadcastGroupsActivationStatus(action) {
  try {
    const {
      status, props: { firestore, profile: { customerId } }
    } = action;
    const docRef = yield firestore.collection('customers').doc(customerId);

    yield docRef.set({
      broadcastGroupActivationStatus: status,
    }, { merge: true });
    notification.success({
      message: 'Status mis à jour avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour le status',
      description: err.toString(),
    });
  }
}

export function* BroadcastGroupsSaga() {
  yield takeLatest(BroadcastGroups.BROADCAST_GROUPS_ADDING, createBroadcastGroup);
  yield takeLatest(BroadcastGroups.BROADCAST_GROUPS_UPDATE, updateBroadcastGroup);
  yield takeLatest(BroadcastGroups.BROADCAST_GROUPS_STATUS_UPDATE, updateBroadcastGroupStatus);
  yield takeLatest(BroadcastGroups.BROADCAST_GROUPS_DELETE, deleteBroadcastGroup);
  yield takeLatest(BroadcastGroups.BROADCAST_GROUPS_ACTIVATE_STATUS, updateBroadcastGroupsActivationStatus);
}
