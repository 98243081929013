/**
 * Return the French provider type
 * @param {string} id
 * @returns {string}
 */
const getThirdPartyTypeName = (id = '') => {
  const typeNamesMap = new Map([
    ['provider', 'Fournisseur'],
    ['customer', 'Client']
  ]);

  return typeNamesMap.get(id) || 'Prestataire';
};

/**
 * Option for form and filter select
 * @type {[{label: string, value: string, key: string}, {label: string, value: string, key: string}, {label: string, value: string, key: string}]}
 */
const typeOptions = [
  {
    label: 'Fournisseur de matière première',
    value: 'provider',
    key: 'provider',
  },
  {
    label: "Fournisseur d'emballage",
    value: 'packagingSupplier',
    key: 'packagingSupplier',
  },
  {
    label: 'Prestataire',
    value: 'serviceProvider',
    key: 'serviceProvider',
  }
];

const englishToFrenchTypes = [
  {
    english: 'provider',
    french: 'Fournisseur de matière première'
  },
  {
    english: 'serviceProvider',
    french: 'Prestataire'
  },
  {
    english: 'packagingSupplier',
    french: 'Fournisseur d\'emballage'
  },
  {
    english: 'matter',
    french: 'Matière'
  },
  {
    english: 'packaging',
    french: 'Emballage'
  },
];

/**
 * Retrieves the French type label corresponding to the given English label.
 *
 * @param {string} label - The English label for which to retrieve the French type label.
 * @returns {string|undefined} The French type label if found, or undefined if not found.
 */
const getFrenchTypeLabel = (label) => englishToFrenchTypes.find(item => item.english === label)?.french;

const getConcernedFrenchLabel = concern => (concern === 'concerned' ? 'Concerné' : 'Non concerné');

/**
 * Retrieves the provider name from the given ID.
 *
 * @param {number} id - The ID of the provider.
 * @param {Array<Object>} providers - The array of provider objects.
 * @returns {string | undefined} - The name of the provider, or undefined if not found.
 */
const getProviderNameFromId = (id, providers) => providers.find(provider => provider.id === id)?.name;

/**
 * Retrieves the names of providers based on the given matters, providers, and getProviderId function.
 *
 * @param {Array} matters - An array of matters.
 * @param {Array} providers - An array of providers.
 * @param {Function} getProviderId - A function that returns the provider ID for a given matter.
 * @returns {Array} - An array containing the names of providers associated with each matter.
 */
const getProvidersNameFromMatters = (matters, providers, getProviderId) => matters.map(
  matter => getProviderNameFromId(getProviderId(matter), providers)
);

/**
 * Helper function to find an item in a list by id
 * @param {Array} list - The list to search in.
 * @param {string|number} id - The id of the item to find.
 * @returns {object} - The found item, or undefined if no item is found.
 */
function findItemById(list, id) {
  return list.find(item => item.id === id);
}

/** Retrieves the name of the third Party with the given ID.
 * @param {number} thirdPartyId
 * @param {Array} thirdPartiesList
 * @returns {string} The found name or an empty string
 */
function getThirdpartyNameById(thirdPartyId, thirdPartiesList) {
  const thirdParty = findItemById(thirdPartiesList, thirdPartyId);
  return thirdParty?.name || '';
}

export {
  getThirdPartyTypeName,
  typeOptions,
  getFrenchTypeLabel,
  getConcernedFrenchLabel,
  getProviderNameFromId,
  getProvidersNameFromMatters,
  getThirdpartyNameById,
};

export * from './providers';
