import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import _ from 'lodash';

import Documents from './constants';
import { monitorFileUpload } from '../../../services/documents';

function* onDocumentAdding(action) {
  const {
    date, customerId, actionId, themeId, majorThemeId, cb, props, chosen
  } = action;
  try {
    const { title, type, document } = action.item;
    const comments = action.item.comments || null;
    const isPublic = action.item.public || false;
    const visibility = action.item.public || false;
    const replace = action.item.replace || false;
    const actionPlanId = action.actionPlanId || null;
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('documents').doc();
    const storageRef = props.firebase.storage().ref(`company/${customerId}`);
    const pdfRef = storageRef.child(new Date().getTime() + document.file.name);
    notification.success({
      message: 'Envoi du document en cours...',
      description: `${title} sera disponile dans quelques secondes`,
      duration: 5,
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
    const task = pdfRef.put(document.file);
    monitorFileUpload(props.dispatch, task);
    const uploadProgress = ratio => Math.round(ratio * 100);

    task.on('state_changed', snapshot => {
      const progress = uploadProgress(
        snapshot.bytesTransferred / snapshot.totalBytes
      );
      if (snapshot && snapshot.state === 'running') {
        props.dispatch({
          type: Documents.DOCUMENT_UPLOADING,
          payload: progress
        });
      }
    });
    yield task.then(() => {
      props.dispatch({
        type: Documents.DOCUMENT_UPLOADED,
      });
      pdfRef.getDownloadURL().then((downloadUrl) => {
        docRef.set({
          title,
          type,
          comments,
          isPublic,
          visibility,
          hasReplaced: replace,
          date,
          actionId,
          themeId,
          majorThemeId,
          actionPlanId,
          customerId,
          downloadUrl,
        }).then(() => {
          pdfRef.getMetadata().then((metadata) => {
            docRef.set({
              name: metadata.name,
              contentType: metadata.contentType,
              fullPath: metadata.fullPath,
              timeCreated: metadata.timeCreated,
              update: metadata.updated,
            }, { merge: true });
          });
        });
      });
    });

    if (!_.isEmpty(chosen)) {
      chosen.forEach((doc) => {
        const pdfRefToDelete = storageRef.child(doc.name);
        pdfRefToDelete.delete().then(() => {
          props.firestore.collection('customers').doc(customerId).collection('documents').doc(doc.id)
            .delete()
            .then(res => console.log(res, 'res deleted'));
        }).catch(e => {
          console.log(e.toString());
        });
      });
    }
    notification.success({
      message: 'Document ajouté avec succès',
      description: `${title} est créé`,
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le document',
      description: err.toString(),
    });
  }
}

function* onDocumentUpdate(action) {
  const {
    current,
    id,
    props,
    majorThemeId,
    cb,
    chosen,
    newTheme,
  } = action;
  try {
    const { title, type, themeId } = action.item;
    const comments = action.item.comments || null;
    const isPublic = action.item.public || false;
    const visibility = action.item.public || false;
    const replace = action.item.replace || false;
    const { profile: { customerId } } = props;
    const storageRef = props.firebase.storage().ref(`company/${customerId}`);

    if (newTheme) {
      const date = new Date();
      const actionRef = yield props.firestore.collection('customers')
        .doc(customerId)
        .collection('actions').doc();

      yield actionRef.set({
        title: 'Changement de thème',
        subTheme: themeId,
        comments,
        date: date.toLocaleString(),
        customerId,
        status: false,
        id: actionRef.id,
      });
      notification.success({
        message: 'Nous créons une action pour indiquer le changement de thème',
        description: 'L\'action est créé',
      });
      const ref = yield props.firestore.collection('customers').doc(customerId).collection('documents').doc();
      const docDate = current.date || null;
      const actionId = actionRef.id;
      const downloadUrl = current.downloadUrl || null;
      const name = current.name || null;
      const contentType = current.contentType || null;
      const fullPath = current.fullPath || null;
      const timeCreated = current.timeCreated || null;
      const updated = current.updated || null;

      yield ref.set({
        title,
        type,
        comments,
        isPublic,
        visibility,
        hasReplaced: replace,
        date: docDate,
        actionId,
        themeId,
        majorThemeId,
        customerId,
        downloadUrl,
        name,
        contentType,
        fullPath,
        timeCreated,
        updated,
      }).then(() => {
        if (!_.isEmpty(chosen)) {
          chosen.forEach((doc) => {
            const pdfRefToDelete = storageRef.child(doc.name);
            pdfRefToDelete.delete().then(() => {
              props.firestore.collection('customers').doc(customerId).collection('documents').doc(doc.id)
                .delete();
            });
          });
        }
        props.history.push(`/company/${customerId}/action/${actionRef.id}/details`);
      });
    } else {
      const docRef = yield props.firestore.collection('customers').doc(customerId).collection('documents').doc(id);
      yield docRef.set({
        title,
        type,
        comments,
        isPublic,
        visibility,
      }, { merge: true }).then(() => {
        notification.success({
          message: 'Document modifié avec succès',
          description: `${title} est modifié`,
        });
        if (!_.isEmpty(chosen)) {
          chosen.forEach((doc) => {
            const pdfRefToDelete = storageRef.child(doc.name);
            pdfRefToDelete.delete().then(() => {
              props.firestore.collection('customers').doc(customerId).collection('documents').doc(doc.id)
                .delete();
            });
          });
        }
        // eslint-disable-next-line no-unused-expressions
        cb && cb();
      });
    }
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier le document',
      description: err.toString(),
    });
  }
}

function* onDeleteDocument(action) {
  try {
    const {
      actionId, customerId, name, props
    } = action;
    const storageRef = props.firebase.storage().ref(`company/${customerId}`);
    const pdfRef = storageRef.child(name);

    yield pdfRef.delete().then(() => {
      props.firestore.collection('customers').doc(customerId).collection('documents').doc(actionId)
        .delete();
    });
    notification.success({
      message: 'Document supprimé avec succès',
      description: `${name} est supprimé`,
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer le document',
      description: err.toString(),
    });
  }
}

function* onDocumentVisibilityUpdate(action) {
  try {
    const {
      id, visibility, props, cb
    } = action;
    const { profile: { customerId } } = props;
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('documents').doc(id);
    yield docRef.set({
      visibility,
    }, { merge: true });
    notification.success({
      message: 'Document mis à jour avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour le document',
      description: err.toString(),
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* DocumentSaga() {
  yield takeLatest(Documents.DOCUMENT_ADDING, onDocumentAdding);
  yield takeLatest(Documents.DOCUMENT_DELETE, onDeleteDocument);
  yield takeLatest(Documents.DOCUMENT_VISIBILITY_UPDATE, onDocumentVisibilityUpdate);
  yield takeLatest(Documents.DOCUMENT_UPDATING, onDocumentUpdate);
}
