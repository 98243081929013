const User = {
  ADD_USER: 'user/add',
  UPDATE_USER_PROFILE: 'user/update_profile',
  UPDATE_USER_EMAIL: 'user/update_email',
  DELETE_USER: 'user/delete',
  USER_ADDED: 'user/added',
  USER_UPDATE_STATUS: 'user/updatestatus',
  USER_CUSTOMER_UPDATE: 'user/customerUpdate',
  USER_UPDATE_PROFILE_BY_ADMIN: 'user/update_profile_by_admin',
};

export default User;
