const Actions = {
  ACTION_ADDING: 'action/add',
  ACTION_UPDATE: 'action/update',
  ACTION_DELETE: 'action/delete',
  ACTION_TERMINATE: 'action/terminate',
  ACTION_REOPEN: 'action/reopen',
  ACTION_DOEXPORT: 'action/doexport',
  SIMPLE_ACTION_ADD: 'simple_action/add',
};

export default Actions;
