const NonComformities = {
  NON_COMFORMITIES_ADDING: 'NonComformities/add',
  NON_COMFORMITIES_UPDATE: 'NonComformities/update',
  NON_COMFORMITIES_DELETE: 'NonComformities/delete',
  NON_COMFORMITIES_TERMINATE: 'NonComformities/terminate',
  NON_COMFORMITIES_EFFICIENCY: 'NonComformities/efficiency',
  NON_COMFORMITY_ADD_IMAGE: 'NonComformities/add_image',
  NON_COMFORMITIES_IMAGE_UPLOADED: 'NonComformities/image_uploaded',
  NON_COMFORMITIES_DOEXPORT: 'NonComformities/export',
  NON_COMFORMITIES_EXPORT_STARTED: 'NonComformities/export_started',
  NON_COMFORMITIES_EXPORT_ENDED: 'NonComformities/export_ended',
  NON_COMFORMITY_REMOVE_IMAGE: 'NonComformities/remove_image',
  NON_COMFORMITY_REMOVE_ALL_IMAGE: 'NonComformities/remove_all_image',
  NON_COMFORMITY_SEND_EMAIL: 'NonComformities/send_email',
};

export default NonComformities;
