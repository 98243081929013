import React from 'react';
import { Badge, Tooltip } from 'antd';
import _ from 'lodash';

const providerDocs = ['traceability', 'fraud'];
const serviceDocs = ['haccpPrest'];

/**
 * Retrieves the documentation for a given provider.
 *
 * @param {Object} provider - The provider object to retrieve documentation for.
 * @return {Array} - An array of documentation properties for the given provider.
 */
function getProviderDocs(provider) {
  const props = [...providerDocs];
  if (provider.type === 'provider') {
    props.push('foodContact');
    ['ogm', 'nano', 'ionisation'].forEach(prop => {
      if (provider[prop] === 'concerned') {
        props.push(prop);
      }
    });
  }
  return props;
}

/**
 * Retrieves the documentation for the packaging suppliers.
 *
 * @return {Array} The packaging supplier documentation.
 */
function getPackagingSupplierDocs() {
  return [...providerDocs];
}

/**
 * Returns the service provider documentation properties for the given provider.
 *
 * @param {object} provider - The service provider name.
 * @return {Array<string>} - An array of service provider documentation properties.
 */
function getServiceProviderDocs(provider) {
  const props = [...serviceDocs];
  ['traceability', 'fraud'].forEach(prop => {
    if (provider[prop]) {
      props.push(prop);
    }
  });
  return props;
}

/**
 * Retrieves the documentation to be checked based on the provider type.
 *
 * @param {object} provider - The provider object.
 * @param {string} provider.type - The type of the provider (e.g., "provider", "packagingSupplier", "serviceProvider").
 * @return {array} - An array of documentation to be checked.
 */
export function getDocsToCheck(provider) {
  switch (provider.type) {
    case 'provider':
      return getProviderDocs(provider);
    case 'packagingSupplier':
      return getPackagingSupplierDocs();
    case 'serviceProvider':
      return getServiceProviderDocs(provider);
    default:
      return [];
  }
}

/**
 * Return common documents to check
 * @returns {string[]}
 */
const getCommonDocsToCheck = () => ['haccp', 'contaminants'];

/**
 * Return additional documents depending on matter type
 * @param matterType
 * @returns {string[]}
 */
const getAdditionalDocsToCheck = (matterType) => {
  if (matterType === 'matter') {
    return ['allergensQuest', 'allergensQuestCross', 'allergens', 'origin', 'nutritionalValues'];
  }
  return ['foodContact'];
};

/**
 * Return documents that need to be checked
 * @param matter
 * @returns {string[]}
 */
export const getMatterDocsToCheck = (matter) => {
  const commonProps = getCommonDocsToCheck();
  const additionalProps = getAdditionalDocsToCheck(matter.type);
  return [...commonProps, ...additionalProps];
};

/**
 * Return all matters waiting documents counts
 * @param matters
 * @param providersDocuments
 * @returns {number}
 */
const getMattersWaitingDocumentsCount = (matters, providersDocuments) => {
  let count = 0;
  matters.forEach(matter => {
    const matterDocsToCheck = getMatterDocsToCheck(matter);
    matterDocsToCheck.forEach(matterDoc => {
      if (
        (!providersDocuments.find(doc => doc.name === `${matterDoc}_matter_doc` && doc.matterId === matter.id))
        || (providersDocuments.find(doc => doc.name === `${matterDoc}_matter_doc`
          && doc.matterId === matter.id
          && !doc.validity))) {
        count += 1;
      }
    });
  });
  return count;
};

/**
 * Return of needed documents count
 * @param providersDocuments
 * @param provider
 * @returns {number}
 */
export const getProviderWaitingDocumentsCount = (provider, providersDocuments) => {
  let count = 0;
  const props = getDocsToCheck(provider);
  props.forEach(prop => {
    const document = providersDocuments.find(doc => doc.name === `${prop}_doc`);
    if (!document || (document && !document.validity)) {
      count += 1;
    }
  });
  return count;
};

/**
 * Return Badge with count for provider waiting documents
 * @param provider
 * @param providersDocuments
 * @param title
 * @returns {JSX.Element|null}
 */
export const showProviderDetailsBadge = (provider, providersDocuments, title = null) => {
  const providerWaitingDocumentsCount = getProviderWaitingDocumentsCount(provider, providersDocuments);
  if (!providerWaitingDocumentsCount) return title;
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {title && (
        `${title} `
      )}
      <Tooltip title={`${providerWaitingDocumentsCount} document(s) en attente`}>
        <Badge dot status="error" title="Des documents sont attendus" />
      </Tooltip>
    </>
  );
};

/**
 * Return Badge with count for provider matters waiting documents
 * @param type
 * @param matters
 * @param providersDocuments
 * @param title
 * @returns {JSX.Element|null}
 */
export const showProviderMattersDetailsBadge = (type, matters, providersDocuments, title = null) => {
  const providerMattersWaitingDocumentsCount = getMattersWaitingDocumentsCount(matters, providersDocuments, type);
  if (!title) {
    title = type === 'provider' ? 'Matières' : 'Emballages';
  }
  if (!providerMattersWaitingDocumentsCount) return title;
  return (
    <>
      {title && (
        `${title} `
      )}
      <Tooltip title={`${providerMattersWaitingDocumentsCount} document(s) en attente`}>
        <Badge dot status="error" title="Des documents sont attendus" />
      </Tooltip>
    </>
  );
};

/**
 * * Given a string return the french name and model
 * @param doc
 * @returns {*}
 */
export const getDocFrenchNameAndModel = (doc) => {
  const names = {
    traceability: {
      title: 'Attestation Traçabilité',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Trac%CC%A7abilite%CC%81_FOU_MP-EMB_PREST.docx?alt=media&token=63a590a6-6f3e-44d2-a5f0-7a51b35952b2',
    },
    haccp: {
      title: 'Attestation HACCP',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_HACCP_FOU_MP-EMB.docx?alt=media&token=83ae3202-216b-40b6-9dc9-6b7314a5d783',
    },
    haccpPrest: {
      title: 'Attestation HACCP',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_HACCP_PREST.docx?alt=media&token=e7ba18be-1d59-4765-945c-d95a15998dcb',
    },
    origin: {
      title: ' Attestation Origine',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Origine_FOU_MP.docx?alt=media&token=0f103202-4d2a-4e27-a5e3-f0e575227f83',
    },
    ionisation: {
      title: 'Attestation non Ionisation',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Non%20Ionisation_FOU_MP.docx?alt=media&token=14182b8d-55c6-4dd1-8958-5d4f08d441de',
    },
    ogm: {
      title: 'Attestation non OGM',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Non%20OGM_FOU_MP.docx?alt=media&token=6afdb71d-f6b4-4b06-bf22-1955b7a7b90a',
    },
    foodContact: {
      title: 'Attestation Contact alimentaire',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Contact%20Alimentaire_FOU_MP-EMB.docx?alt=media&token=89506199-8625-464f-9756-d9b0eae3c318',
    },
    fraud: {
      title: 'Attestation Fraude',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Food%20Fraud_FOU_MP-EMB.docx?alt=media&token=c0cb4c34-a705-4755-836d-64413a464e69',
    },
    nano: {
      title: 'Attestation abscence Nanomatériaux',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Absence_nanomate%CC%81riaux_FOU_MP.docx?alt=media&token=1ec0ffc0-b956-491b-900b-5335481de0ce',
    },
    allergens: {
      title: 'Attestation Allergènes',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Allerge%CC%80nes_FOU_MP.docx?alt=media&token=170e1051-9b76-4b0c-9c0b-c3ec0a5021df',
    },
    allergensQuest: {
      title: 'Questionnaire Allergènes',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FFOU_MP_Mode%CC%80le_Questionnaire%20Allerge%CC%80nes.xlsx?alt=media&token=055bc593-3007-41c4-b917-cee2ec810cfa',
    },
    allergensQuestCross: {
      title: 'Questionnaire Allergènes contamination croisée',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FFOU_MP_Modele_Questionnaire%20%20Allerge%CC%80nes%20conta%20croise%CC%81e.xlsx?alt=media&token=8b6d479d-164a-4225-a82c-9828c5c8e88a',
    },
    contaminants: {
      title: 'Attestation Contaminants',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FAttestation_Contaminants_FOU_MP-EMB.docx?alt=media&token=d60aceda-605a-43c4-b7ed-46e4bb1de992'
    },
    nutritionalValues: {
      title: 'Questionnaire Valeurs Nutritionnelles',
      // eslint-disable-next-line max-len
      model: 'https://firebasestorage.googleapis.com/v0/b/axelcontrol-98dd0.appspot.com/o/modeles%2FFOU_MP_Mode%CC%80le_Questionnaire%20Val.Nut.xlsx?alt=media&token=802987dd-f8b2-4e19-9f80-8487a1abeb4d',
    },
  };
  return names[doc];
};

/**
 * Return a label and a badge if a doc is needed
 * @param doc
 * @param type
 * @returns {JSX.Element|*}
 */
export const addTitleAndBadge = (doc, type = null) => {
  const docName = getDocFrenchNameAndModel(doc);
  if (doc !== type) {
    return docName.title;
  }
  return (
    <Badge status="processing" text={docName.title} />
  );
};

/**
 * Finds the documents that are waiting to be checked for validity.
 *
 * @param {object} currentProvider - The current provider.
 * @param {Array} providersDocuments - The list of documents for all providers.
 * @return {Array} - The documents that are waiting to be checked for validity.
 */
export const getWaitingDocs = (currentProvider, providersDocuments) => {
  const docsToShow = [];
  const docsToCheck = getDocsToCheck(currentProvider);

  docsToCheck.forEach(document => {
    const matchedDocument = providersDocuments.find(doc => doc.name === `${document}_doc`);
    if (!matchedDocument || !matchedDocument.validity) {
      docsToShow.push(document);
    }
  });

  return docsToShow;
};

/**
 * Filters an array of documents by matterId.
 *
 * @param {string} id - The matterId to filter documents by.
 * @param {Array} documents - The array of documents to be filtered.
 * @returns {Array} - The filtered array of documents with matching matterId.
 */
const filterProviderDocsByMatterId = (id, documents) => documents.filter(doc => doc.matterId === id);

/**
 * Finds if a document is present and valid.
 *
 * @param {Array} filteredDocuments - The list of documents filtered by matter ID.
 * @param {string} documentName - The document to find and check.
 * @returns {boolean} - Returns true if the document is missing or invalid, otherwise returns false.
 */
const isDocMissingOrInvalid = (filteredDocuments, documentName) => {
  const foundDoc = filteredDocuments.find(doc => doc.name === `${documentName}_matter_doc`);
  return !foundDoc || !foundDoc.validity;
};

/**
 * Retrieves the matter documents that are waiting for completion.
 *
 * @param {object} matter - The matter object.
 * @param {array} providersDocuments - The provider documents array.
 * @returns {array} - The matter documents waiting for completion.
 */
export const getMatterWaitingDocs = (matter, providersDocuments) => {
  const matterDocsToCheck = getMatterDocsToCheck(matter);
  const filteredDocumentsByMatterId = filterProviderDocsByMatterId(matter.id, providersDocuments);

  return matterDocsToCheck.filter(
    matterDoc => isDocMissingOrInvalid(filteredDocumentsByMatterId, matterDoc)
  );
};

/**
 * Checks the status of matters based on provider and provider documents.
 *
 * @param {Object} provider - The provider object.
 * @param {Array} matters - An array of matters to check.
 * @param {Array} providerDocuments - An array of provider documents.
 *
 * @returns {boolean} - Returns true if all matters have no waiting documents, false otherwise.
 */
const getMatterStatus = (provider, matters, providerDocuments) => matters.every(
  matter => getMatterWaitingDocs(matter, providerDocuments).length === 0
);

/**
 * Checks if the provider's documents status is complete.
 *
 * @param {Object} provider - The provider object.
 * @param {Array} matters - The array of matter objects.
 * @param {Array} providerDocuments - The array of provider document objects.
 * @returns {boolean} - True if the provider's documents status is complete, otherwise false.
 */
export const isProviderDocumentsStatusComplete = (provider, matters, providerDocuments) => {
  const providersDocsToCheck = getWaitingDocs(provider, providerDocuments);
  return _.isEmpty(providersDocsToCheck) && getMatterStatus(provider, matters, providerDocuments);
};
