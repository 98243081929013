import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import SubThemes from './constants';

function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function* onSubThemeAdding(action) {
  try {
    const {
      title,
    } = action.item;
    const formLinks = action.item.formLinks || null;
    const information = action.item.information || null;
    const status = action.item.status || true;
    const { cb, parentId, isSub } = action;
    const docRef = yield (action.props.firestore.collection('subThemes').doc());
    yield docRef.set({
      title,
      formLinks,
      parentId,
      isSub,
      status,
      information,
      id: docRef.id,
    });
    if (isSub) {
      action.props.firestore.collection('subThemes').doc(parentId).set({ hasSub: true }, { merge: true });
      action.props.firestore.collection('subThemes').doc(parentId).collection('childThemes').add({
        id: docRef.id,
        status,
      });
    }
    notification.success({
      message: 'Sous Thème ajouté avec succès',
      description: `${title} est créé`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le sous thème',
      description: err.toString(),
    });
  }
}

function* onUpdateSuccess(action) {
  try {
    const { title } = action.item;
    const information = action.item.information || null;
    const formLinks = action.item.formLinks || null;
    const { cb } = action;
    const docRef = yield (action.props.firestore.collection('subThemes').doc(action.id));
    yield docRef.set({
      title,
      information,
    }, { merge: true });
    if (isEmpty(formLinks)) {
      docRef.set({
        formLinks,
      }, { merge: true });
    }
    formLinks.forEach((link) => {
      docRef.set({
        formLinks: action.props.firebase.firestore.FieldValue.arrayUnion(link),
      }, { merge: true });
    });
    notification.success({
      message: 'Sous thème modifié avec succès',
      description: `${title} est modifié`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer le sous thème',
      description: err.toString(),
    });
  }
}

export function* onThemeArchiveStatusUpdate(action) {
  try {
    const { id, props, value } = action;
    const docRef = props.firestore.collection('subThemes').doc(id);
    yield docRef.set({
      status: value,
    }, { merge: true });
    notification.success({
      message: 'Status modifié avec succès',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'archiver le sous thème',
      description: err.toString(),
    });
  }
}

export function* SubThemesSaga() {
  yield takeLatest(SubThemes.ADDING_SUB_THEME, onSubThemeAdding);
  yield takeLatest(SubThemes.SUBTHEME_UPDATE_SUCCESS, onUpdateSuccess);
  yield takeLatest(SubThemes.SUBTHEME_ARCHIVE_ACTION, onThemeArchiveStatusUpdate);
}
