import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import firebase from 'firebase/app';
import history from './utils/history';
import configureStore from './stores/Store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './containers/app/Loadable';

// Sentry init
Sentry.init({
  dsn: 'https://cec91e0ca8a847b0b9b0ecfceb2aca87@o120608.ingest.sentry.io/5236414',
  integrations: [
    // Add browser profiling integration to the list of integrations
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
// Log Rocket
LogRocket.init('jofqld/axelcontrol');
LogRocket.getSessionURL(sessionURL => {
  Sentry.configureScope(scope => {
    scope.setExtra('sessionURL', sessionURL);
  });
});
setupLogRocketReact(LogRocket);

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);

const rrfConfig = {
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  userProfile: 'users', // firebase root where user profiles are stored
  logErrors: false,
  enableLogging: true, // enable/disable Firebase's database logging
  autoPopulateProfile: true,
  profileParamsToPopulate: [['role:roles']],
  fileMetadataFactory: (uploadRes, firestore, metadata, downloadURL) => {
    // upload response from Firebase's storage upload
    const { metadata: { name, fullPath } } = uploadRes;
    // default factory includes name, fullPath, downloadURL
    return {
      name,
      fullPath,
      downloadURL
    };
  }
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter store={store} history={history}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </ConnectedRouter>
  </Provider>
);

// eslint-disable-next-line no-undef
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
