import {
  takeLatest
} from 'redux-saga/effects';
import _ from 'lodash';
import { notification } from 'antd';
import Exporter from '../../../modules/shared/exporter/Exporter';
import ExporterFields from './export/ExporterFields';
import { getCompanyStorageRef, setImmediateCorrectionDueDateValue } from '../../../services/nonComformities';


import NonComformities from './constants';

const BASE_URL = 'https://us-central1-axelcontrol-98dd0.cloudfunctions.net';

const momentToLocalString = (moment) => {
  if (_.isNull(moment) || !_.isObject(moment)) return moment;
  return moment.toDate().toLocaleString();
};

function* sendEmail(action) {
  const { props } = action;
  const msg = action.item;
  const url = `${BASE_URL}/sendNonConformityByEmail`;
  const idToken = yield props.firebase.auth().currentUser.getIdToken(true);

  try {
    // eslint-disable-next-line no-undef
    yield fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(msg),
    });
    notification.success({
      message: 'Message envoyé avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'envoyer le message',
      description: err.toString(),
    });
  }
}

function* onNonComformityAdding(nonComformity) {
  try {
    const { openDate, origin, colleague } = nonComformity.item;
    const detailOrigin = nonComformity.item.detailOrigin || null;
    const delta = nonComformity.item.delta || null;
    const severityIndex = nonComformity.item.severityIndex || null;
    const cause = nonComformity.item.cause || null;
    const immediateCorrection = nonComformity.item.immediateCorrection || null;
    const immediateCorrectionResponsible = nonComformity.item.immediateCorrectionResponsible || null;
    const immediateCorrectionDoneDate = nonComformity.item.immediateCorrectionDoneDate || null;
    const correctiveAction = nonComformity.item.correctiveAction || null;
    const correctiveActionResponsible = nonComformity.item.correctiveActionResponsible || null;
    const correctiveActionDueDate = nonComformity.item.correctiveActionDueDate || null;
    const correctiveActionDoneDate = nonComformity.item.correctiveActionDoneDate || null;
    const efficiency = nonComformity.item.efficiency || null;
    const checkDate = nonComformity.item.checkDate || null;
    const images = nonComformity.item.images || null;
    const docs = nonComformity.item.docs || null;
    const status = nonComformity.item.closeIt || false;
    let thirdParty = nonComformity?.item?.thirdParty || null;
    const batchNumber = nonComformity?.item?.batchNumber ?? null;
    const quantityConcerned = nonComformity?.item?.quantityConcerned ?? null;
    const otherStatus = nonComformity?.item?.otherStatus ?? null;
    const cnq = nonComformity?.item?.cnq || null;
    const immediateCorrectionEfficiencyComment = nonComformity?.item?.immediateCorrectionEfficiencyComment || null;
    const immediateCorrectionCheckDate = nonComformity?.item?.immediateCorrectionCheckDate || null;
    const customerResponseDate = nonComformity?.item?.customerResponseDate || null;

    if (thirdParty === 'empty') {
      thirdParty = null;
    }

    const {
      props, cb, customerId
    } = nonComformity;
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('nonComformities').doc();

    yield docRef.set({
      openDate: openDate.toDate().toLocaleString(),
      origin,
      customerId,
      detailOrigin,
      delta,
      severityIndex,
      cause,
      immediateCorrection,
      immediateCorrectionResponsible,
      immediateCorrectionDueDate: momentToLocalString(setImmediateCorrectionDueDateValue(openDate, severityIndex)),
      immediateCorrectionDoneDate: momentToLocalString(immediateCorrectionDoneDate),
      correctiveAction,
      correctiveActionResponsible,
      correctiveActionDueDate: momentToLocalString(correctiveActionDueDate),
      correctiveActionDoneDate: momentToLocalString(correctiveActionDoneDate),
      efficiency,
      checkDate: momentToLocalString(checkDate),
      images,
      docs,
      id: docRef.id,
      status,
      thirdParty,
      batchNumber,
      quantityConcerned,
      otherStatus,
      cnq,
      colleague,
      immediateCorrectionEfficiencyComment,
      immediateCorrectionCheckDate: momentToLocalString(immediateCorrectionCheckDate),
      customerResponseDate: momentToLocalString(customerResponseDate),
    });
    notification.success({
      message: (!nonComformity.item.closeIt) ? 'Non conformité ajoutée avec succès' : 'Non conformité ajoutée et fermée avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb(docRef.id);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter la non conformité',
      description: err.toString(),
    });
  }
}

function* onNonComformityUpdate(action) {
  try {
    const { openDate, origin, colleague } = action.item;
    const detailOrigin = action.item.detailOrigin || null;
    const delta = action.item.delta || null;
    const severityIndex = action.item.severityIndex || null;
    const cause = action.item.cause || null;
    const immediateCorrection = action.item.immediateCorrection || null;
    const immediateCorrectionResponsible = action.item.immediateCorrectionResponsible || null;
    const immediateCorrectionDoneDate = action.item.immediateCorrectionDoneDate || null;
    const correctiveAction = action.item.correctiveAction || null;
    const correctiveActionResponsible = action.item.correctiveActionResponsible || null;
    const correctiveActionDueDate = action.item.correctiveActionDueDate || null;
    const correctiveActionDoneDate = action.item.correctiveActionDoneDate || null;
    const efficiency = action.item.efficiency || null;
    const checkDate = action.item.checkDate || null;
    const images = action.item.images || null;
    const docs = action.item.docs || null;
    const status = action.item.closeIt || false;
    let thirdParty = action.item.thirdParty || null;
    const batchNumber = action?.item?.batchNumber ?? null;
    const quantityConcerned = action?.item?.quantityConcerned ?? null;
    const otherStatus = action?.item?.otherStatus ?? null;
    const cnq = action?.item?.cnq || null;
    const immediateCorrectionEfficiencyComment = action?.item?.immediateCorrectionEfficiencyComment || null;
    const immediateCorrectionCheckDate = action?.item?.immediateCorrectionCheckDate || null;
    const customerResponseDate = action?.item?.customerResponseDate || null;

    if (thirdParty === 'empty') {
      thirdParty = null;
    }

    const {
      props, cb, id,
    } = action;
    const { profile: { customerId } } = props;
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('nonComformities').doc(id);

    yield docRef.set({
      openDate: openDate.toDate().toLocaleString(),
      origin,
      detailOrigin,
      delta,
      severityIndex,
      cause,
      immediateCorrection,
      immediateCorrectionResponsible,
      immediateCorrectionDueDate: momentToLocalString(setImmediateCorrectionDueDateValue(openDate, severityIndex)),
      immediateCorrectionDoneDate: momentToLocalString(immediateCorrectionDoneDate),
      correctiveAction,
      correctiveActionResponsible,
      correctiveActionDueDate: momentToLocalString(correctiveActionDueDate),
      correctiveActionDoneDate: momentToLocalString(correctiveActionDoneDate),
      efficiency,
      images,
      docs,
      checkDate: momentToLocalString(checkDate),
      status,
      thirdParty,
      batchNumber,
      quantityConcerned,
      otherStatus,
      cnq,
      colleague,
      immediateCorrectionEfficiencyComment,
      immediateCorrectionCheckDate: momentToLocalString(immediateCorrectionCheckDate),
      customerResponseDate: momentToLocalString(customerResponseDate),
    }, { merge: true });
    notification.success({
      message: (!action.item.closeIt) ? 'Non conformité modifiée avec succès' : 'Non conformité modifiée et fermée avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb(docRef.id);
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier la non conformité',
      description: err.toString(),
    });
  }
}

function* onDeleteRequest(action) {
  try {
    const { id, props, cb } = action;
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
    const { profile: { customerId } } = props;
    yield props.firestore.collection('customers').doc(customerId).collection('nonComformities').doc(id)
      .delete();
    notification.success({
      message: 'Non conformité supprimée avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer la non conformité',
      description: err.toString(),
    });
  }
}

function* onEfficiencyUpdate(action) {
  try {
    const { efficiency, checkDate, id } = action.item;
    const efficiencyComment = action.item.efficiencyComment || null;
    const {
      props, cb,
    } = action;
    const { profile: { customerId } } = props;
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('nonComformities').doc(id);

    yield docRef.set({
      efficiency,
      checkDate: momentToLocalString(checkDate),
      efficiencyComment
    }, { merge: true });
    notification.success({
      message: 'Efficacité mise à jour avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour le status',
      description: err.toString(),
    });
  }
}

function* onTerminate(action) {
  try {
    const { id, props, cb } = action;
    const { profile: { customerId } } = props;
    const docRef = props.firestore.collection('customers').doc(customerId).collection('nonComformities').doc(id);
    yield docRef.set({
      status: true,
    }, { merge: true });
    notification.success({
      message: 'Non conformité fermée avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de fermer la non conformité',
      description: err.toString(),
    });
  }
}

function* exportNonComformities(action) {
  console.log(action.props);
  try {
    const { selectedRows, props } = action;
    const {
      dataSource, dispatch, originItems, users, thirdParties
    } = props;

    dispatch({
      type: NonComformities.NON_COMFORMITIES_EXPORT_STARTED,
    });

    const nonComformitiesToExport = dataSource.filter(nonComformity => selectedRows.includes(nonComformity.id));

    yield new Exporter(
      ExporterFields,
      'Liste des non conformités',
      originItems,
      users,
      thirdParties,
    ).transformAndExportAsExcelFile(nonComformitiesToExport);
    notification.success({
      message: 'Export complété avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de procéder à l\'export',
      description: err.toString(),
    });
  }
}

function* onRemoveImage(action) {
  try {
    const { file, props } = action;
    const { docName, storageRef } = getCompanyStorageRef(file, props);
    const pdfRef = storageRef.child(docName);
    yield pdfRef.delete().then(() => {
      // eslint-disable-next-line no-console
      console.log('deleted');
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.toString());
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err.toString());
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* NonComformitySaga() {
  yield takeLatest(NonComformities.NON_COMFORMITIES_ADDING, onNonComformityAdding);
  yield takeLatest(NonComformities.NON_COMFORMITIES_UPDATE, onNonComformityUpdate);
  yield takeLatest(NonComformities.NON_COMFORMITIES_DELETE, onDeleteRequest);
  yield takeLatest(NonComformities.NON_COMFORMITIES_TERMINATE, onTerminate);
  yield takeLatest(NonComformities.NON_COMFORMITIES_EFFICIENCY, onEfficiencyUpdate);
  yield takeLatest(NonComformities.NON_COMFORMITIES_DOEXPORT, exportNonComformities);
  yield takeLatest(NonComformities.NON_COMFORMITY_REMOVE_IMAGE, onRemoveImage);
  yield takeLatest(NonComformities.NON_COMFORMITY_SEND_EMAIL, sendEmail);
}
