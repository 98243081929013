import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import Login from './constants';

export function* onUserAuth(action) {
  try {
    const { email, password } = action.user;
    yield action.props.firebase.login({ email, password });
    notification.success({
      message: 'Connecté avec succès',
      description: '',
    });
  } catch (err) {
    console.log(err);
  }
}

export function* onUserLogout(action) {
  try {
    yield action.props.firebase.logout();
    notification.success({
      message: 'Déconnecté',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Erreur',
      description: err.toString(),
    });
  }
}

const getMessageFromCode = code => {
  let errorMessage = '';
  switch (code) {
    case 'auth/user-not-found':
      errorMessage = 'Utilisateur inconnu';
      break;
    default:
      errorMessage = 'Erreur technique';
      break;
  }
  return errorMessage;
};

export function* onPasswordForgotten(action) {
  try {
    const { item, props } = action;
    const { email } = item;
    yield props.firebase.auth().sendPasswordResetEmail(email.trim());
    notification.success({
      message: 'Demande de réinitialisation de mot de passe effectuée',
      description: 'Consultez vos emails',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de réinitialiser le mot de passe',
      description: getMessageFromCode(err.code),
    });
  }
}

export function* LoginSaga() {
  yield takeLatest(Login.USER_LOGIN, onUserAuth);
  yield takeLatest(Login.USER_LOGOUT, onUserLogout);
  yield takeLatest(Login.USER_FORGOT_PASSWORD, onPasswordForgotten);
}
