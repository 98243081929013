import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import Type from './constants';

function* onTypeAdding(action) {
  try {
    const { title } = action.item;
    const status = action.item.status || true;
    const { cb } = action;
    const docRef = yield (action.props.firestore.collection('documentTypes').doc());

    yield docRef.set({
      title,
      status,
      id: docRef.id,
    });

    notification.success({
      message: 'Élement ajouté avec succès',
      description: `${title} est créé`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter l\'élément',
      description: err.toString(),
    });
  }
}

export function* onTypeUpdate(action) {
  try {
    const { title } = action.item;
    const status = action.item.status || true;
    const { cb } = action;
    const docRef = action.props.firestore.collection('documentTypes').doc(action.id);
    yield docRef.set({
      title,
      status,
    }, { merge: true });
    notification.success({
      message: 'Élément avec succès',
      description: `${title} est modifié`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer l\'élément',
      description: err.toString(),
    });
  }
}

export function* onTypeStatusUpdate(action) {
  try {
    const { id, props, value } = action;
    const docRef = props.firestore.collection('documentTypes').doc(id);
    yield docRef.set({
      status: value,
    }, { merge: true });
    notification.success({
      message: 'Status modifié avec succès',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'archiver le thème',
      description: err.toString(),
    });
  }
}

export function* TypeSaga() {
  yield takeLatest(Type.TYPE_ADDING, onTypeAdding);
  yield takeLatest(Type.TYPE_UPDATE, onTypeUpdate);
  yield takeLatest(Type.TYPE_STATUS_UPDATE, onTypeStatusUpdate);
}
