import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import Themes from './constants';

function* onThemeAdding(action) {
  try {
    const { title } = action.item;
    const information = action.item.information || null;
    const status = action.item.status || true;
    const { cb } = action;
    const docRef = yield (action.props.firestore.collection('themes').doc());

    yield docRef.set({
      title,
      information,
      status,
      id: docRef.id,
    });

    notification.success({
      message: 'Thème ajouté avec succès',
      description: `${title} est créé`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le thème',
      description: err.toString(),
    });
  }
}

export function* onUpdateRequest(action) {
  try {
    const { title } = action.item;
    const information = action.item.information || null;
    const { cb } = action;
    const docRef = action.props.firestore.collection('themes').doc(action.id);
    yield docRef.set({
      title,
      information,
    }, { merge: true });
    notification.success({
      message: 'Thème modifié avec succès',
      description: `${title} est modifié`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer le thème',
      description: err.toString(),
    });
  }
}

export function* onDeleteRequest(action) {
  try {
    const { id, cb, props } = action;
    const docRef = props.firestore.collection('themes').doc(id);
    yield docRef.delete();
    notification.success({
      message: 'Thème supprimé avec succès',
      description: '',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer le thème',
      description: err.toString(),
    });
  }
}

export function* onThemeArchiveStatusUpdate(action) {
  try {
    const { id, props, value } = action;
    const docRef = props.firestore.collection('themes').doc(id);
    yield docRef.set({
      status: value,
    }, { merge: true });
    notification.success({
      message: 'Status modifié avec succès',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'archiver le thème',
      description: err.toString(),
    });
  }
}

export function* ThemesSaga() {
  yield takeLatest(Themes.ADDING_THEME, onThemeAdding);
  yield takeLatest(Themes.THEME_UPDATE_REQUEST, onUpdateRequest);
  yield takeLatest(Themes.THEME_DELETE_REQUEST, onDeleteRequest);
  yield takeLatest(Themes.THEME_ARCHIVE_ACTION, onThemeArchiveStatusUpdate);
}
