import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Spinner = ({ size }) => (
  <SpinnerWrapper>
    <Spin size={size} />
  </SpinnerWrapper>
);

const SpinnerWrapper = styled.div`
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
`;

Spinner.propTypes = {
  size: PropTypes.string,
};

Spinner.defaultProps = {
  size: 'large',
};

export default Spinner;
