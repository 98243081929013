import { Tag } from 'antd';
import React from 'react';

const getOriginTitleFromOriginId = (originItems, id) => {
  if (!id) return '';
  const origin = originItems.find(item => item.id === id);
  if (!origin) return '';
  const color = origin.color || 'blue';
  return <Tag color={color}>{origin.title}</Tag>;
};

const getTypeFromOrigin = (originItems, id) => {
  let type = '';
  if (!id) return type;
  const origin = originItems.find(item => item.id === id);
  if (!origin) return type;
  type = origin.title.search('NC') === 0 ? 'NC' : 'Réclamation client';
  return type;
};

const getOriginTitle = (originItems, id) => (id && originItems.find(item => item.id === id)?.title) || '';

const DEFAULT_COLOR = '#fff';

const getOriginColor = (originItems, id) => {
  if (!id) return DEFAULT_COLOR;
  const origin = originItems.find(item => item.id === id);
  return origin ? origin.color : DEFAULT_COLOR;
};

export {
  getOriginTitleFromOriginId,
  getOriginTitle,
  getTypeFromOrigin,
  getOriginColor,
};
