import ExporterSchema from '../../../../modules/shared/exporter/ExporterSchema';
import { Excel } from '../../../../modules/shared/excel';
import { getSubThemeTitle } from '../../../../services/subThemes';
import { getDocumentTypeTitleFromTypeId } from '../../../../services/documents';

export default class Exporter {
  constructor(fields, excelFileName, subThemes, documentTypes) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
    this.subThemes = subThemes;
    this.documentTypes = documentTypes;
  }

  formatHeaders =(row) => ({
    id: row.id,
    Date: row.date,
    Titre: row.title,
    Lien: row.downloadUrl,
    Sous_theme: getSubThemeTitle(this.subThemes, row.themeId),
    type: getDocumentTypeTitleFromTypeId(this.documentTypes, row.type),
    Action_titre: row.a_title,
    Action_date: row.a_date,
    Action_status: row.a_status ? 'Terminé' : 'En cours',
    Plan_titre: row.p_title,
    Plan_commentaire: row.p_comments,
    Plan_date_de_creation: row.p_date,
    Plan_date_d_echeance: row.p_dueDate,
    Plan_status: row.p_status ? 'Terminé' : 'En cours',
  });

  transformAndExportAsExcelFile(rows) {
    const exportableData = rows.map((row) => this.formatHeaders(row)
    );

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      `${this.excelFileName} ${new Date().getTime()}`,
    );
  }
}
