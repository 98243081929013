import _ from 'lodash';

const getCompanyStorageRef = (file, props) => {
  const { customerId, path } = props;
  const docType = _.split(file.file.name, '.');
  const docName = `${file.file.uid}.${docType[docType.length - 1]}`;
  const storageRef = props.firebase.storage().ref(`company/${customerId}/${path}`);
  return { docName, storageRef };
};

const setImmediateCorrectionDueDateValue = (openDate, gravityIndex) => {
  let immediateCorrectionDueDate = openDate.clone();
  switch (gravityIndex) {
    case '3':
      immediateCorrectionDueDate = immediateCorrectionDueDate.add('days', 3);
      break;
    case '5':
      immediateCorrectionDueDate = immediateCorrectionDueDate.add('hours', 48);
      break;
    default:
      immediateCorrectionDueDate = immediateCorrectionDueDate.add('days', 8);
  }
  return immediateCorrectionDueDate;
};

export {
  getCompanyStorageRef,
  setImmediateCorrectionDueDateValue,
};
