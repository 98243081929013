import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import ActionPlans from './constants';

function* onActionPlanAdding(action) {
  try {
    const { title } = action.item;
    const dueDate = action.item?.dueDate ? action.item.dueDate.toDate().toLocaleString() : null;
    const toDate = action.item?.toDate ? action.item.toDate.toDate().toLocaleString() : null;
    const comments = action.item.comments || null;
    const {
      date, customerId, actionId, themeId, cb, props
    } = action;
    const docRef = yield props.firestore.collection('customers')
      .doc(customerId)
      .collection('actionPlans')
      .doc();

    docRef.set({
      id: docRef.id,
      title,
      dueDate,
      toDate,
      comments,
      date,
      actionId,
      themeId,
      customerId,
      status: false,
    });
    notification.success({
      message: 'Plan d\'action ajouté avec succès',
      description: `${title} est créé`,
    });
    cb && cb(docRef.id);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le dplan d\'action',
      description: err.toString(),
    });
  }
}

function* onActionPlanUpdate(action) {
  try {
    const { title, dueDate } = action.item;
    const comments = action.item.comments || null;
    const {
      id, updated, cb, props
    } = action;
    const { profile: { customerId } } = props;
    const docRef = props.firestore.collection('customers')
      .doc(customerId)
      .collection('actionPlans')
      .doc(id);

    yield docRef.set({
      title,
      dueDate: dueDate.toDate().toLocaleString(),
      comments,
      updated,
    }, { merge: true });
    notification.success({
      message: 'Plan d\'action modifiée avec succès',
      description: `${title} est modifié`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer le plan d\'action',
      description: err.toString(),
    });
  }
}

function* onDeleteActionPlan(action) {
  try {
    const { id, props, cb } = action;
    const { profile: { customerId } } = props;
    cb && cb();
    yield props.firestore.collection('customers')
      .doc(customerId)
      .collection('actionPlans')
      .doc(id)
      .delete();

    notification.success({
      message: 'Plan d\'action supprimée avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer le plan d\'action',
      description: err.toString(),
    });
  }
}

function* onActionPlanTerminate(action) {
  try {
    const {
      comments, id, props, cb
    } = action;
    const { profile: { customerId } } = props;
    const docRef = props.firestore.collection('customers')
      .doc(customerId)
      .collection('actionPlans')
      .doc(id);

    yield docRef.set({
      comments,
      status: true,
    }, { merge: true });
    notification.success({
      message: 'Plan d\'action terminé avec succès',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de terminer le plan d\'action',
      description: err.toString(),
    });
  }
}

function* onActionPlanReopen(action) {
  try {
    const { id, props, cb } = action;
    const { profile: { customerId } } = props;
    const docRef = props.firestore.collection('customers')
      .doc(customerId)
      .collection('actionPlans')
      .doc(id);

    yield docRef.set({
      status: false,
    }, { merge: true });
    notification.success({
      message: 'Plan d\'action réouvert avec succès',
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de réouvrir le plan d\'action',
      description: err.toString(),
    });
  }
}

export function* ActionPlanSaga() {
  yield takeLatest(ActionPlans.ACTION_PLAN_ADDING, onActionPlanAdding);
  yield takeLatest(ActionPlans.ACTION_PLAN_EDIT, onActionPlanUpdate);
  yield takeLatest(ActionPlans.ACTION_PLAN_DELETE, onDeleteActionPlan);
  yield takeLatest(ActionPlans.ACTION_PLAN_TERMINATE, onActionPlanTerminate);
  yield takeLatest(ActionPlans.ACTION_PLAN_REOPEN, onActionPlanReopen);
}
