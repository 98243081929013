import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import history from '../utils/history';
import themeReducer from '../containers/admin/themes/reducer';
import globalReducer from '../containers/app/reducer';
import documentReducer from '../containers/companies/documents/reducer';
import userReducer from '../containers/admin/users/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form: formReducer,
    global: globalReducer,
    router: connectRouter(history),
    theme: themeReducer,
    document: documentReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    user: userReducer,
    ...injectedReducers
  });
  return rootReducer;
}
