import { Tag } from 'antd';
import React from 'react';
import Documents from '../../containers/companies/documents/constants';

const getTDocumentTypeFromTypeId = (documentTypes, id, url = null) => {
  if (!id) return '';
  const type = documentTypes.find(item => item.id === id);
  return (
    <Tag color="green">
      <a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        title="Visualiser"
      >
        {type.title}
      </a>
    </Tag>
  );
};

const getDocumentTypeTitleFromTypeId = (documentTypes, id) => {
  if (!id) return '';
  const type = documentTypes.find(item => item.id === id);
  return type.title || null;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getTDocumentTypeFromTypeId,
  getDocumentTypeTitleFromTypeId,
};

const uploadProgress = ratio => Math.round(ratio * 100);

export const monitorFileUpload = (dispatch, task) => {
  task.on('state_changed', snapshot => {
    const progress = uploadProgress(
      snapshot.bytesTransferred / snapshot.totalBytes
    );
    if (snapshot && snapshot.state === 'running') {
      dispatch({
        type: Documents.DOCUMENT_UPLOADING,
        payload: progress
      });
    } else {
      dispatch({
        type: Documents.DOCUMENT_UPLOADED,
      });
    }
  });
};
