import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import ThirdParties from './constants';
import { monitorFileUpload } from '../../../services/documents';
import Documents from '../documents/constants';
import Exporter from './documents/export/Exporter';
import moment from 'moment';

/**
 * Create a provider
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* createThirdParty(action) {
  console.log(action, 'action');
  const {
    item, props, customerId, cb
  } = action;

  const comments = item?.comments || null;
  const ionisationRevision = item.ionisationRevision || null;
  const ogmRevision = item.ogmRevision || null;
  const nanoRevision = item.nanoRevision || null;
  const traceabilityRevision = item.traceabilityRevision || null;
  const fraudRevision = item.fraudRevision || null;
  const foodContactRevision = item.foodContactRevision || null;
  const sedex = item.sedex || null;
  const fda = item.fda || null;
  const ogm = item.ogm || null;
  const ionisation = item.ionisation || null;
  const nano = item.nano || null;
  const foodContact = item.foodContact || null;
  const haccpPrest = item.haccpPrest || null;
  const approval = item.approval || null;
  const codeOfConduct = item.codeOfConduct || null;
  const privateNotes = item.privateNotes || null;
  const group = item.group || null;

  const {
    type,
    name,
    mainContact,
    email,
    weekCrisisContact,
    weekEndCrisisContact,
    address,
    qualityContact,
    commercialContact,
    factory,
    certification,
    traceability,
    fraud,
    crisisManagement,
    claimsManagement,
    businessContinuity,
  } = item;

  try {
    const docRef = yield props.firestore.collection('thirdParties').doc();

    yield docRef.set({
      approval,
      haccpPrest,
      name,
      mainContact,
      comments,
      weekCrisisContact,
      weekEndCrisisContact,
      type,
      email,
      status: true,
      id: docRef.id,
      customerId,
      role: 'supplier',
      address,
      qualityContact,
      commercialContact,
      group,
      factory,
      certification,
      sedex,
      fda,
      nano,
      ogm,
      ionisation,
      traceability,
      fraud,
      foodContact,
      crisisManagement,
      claimsManagement,
      businessContinuity,
      codeOfConduct,
      ionisationRevision,
      ogmRevision,
      nanoRevision,
      traceabilityRevision,
      fraudRevision,
      foodContactRevision,
    }).then(() => {
      props.firestore.collection('customers')
        .doc(customerId)
        .collection('thirdParties')
        .doc()
        .set({
          providerId: docRef.id,
          privateNotes
        });
      props.firestore.collection('customers')
        .doc(customerId)
        .update({
          thirdParties: props.firestore.FieldValue.arrayUnion(docRef.id)
        });
    });
    notification.success({
      message: 'Tiers ajouté avec succès',
      description: `${name} est créé`,
    });
    cb && cb(docRef.id);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le tiers',
      description: err.toString(),
    });
  }
}

/**
 * Update a provider
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* updateThirdParty(action) {
  try {
    const {
      item, props, cb, id
    } = action;
    const { profile: { customerId } } = props;
    const comments = item?.comments || null;
    const ionisationRevision = item.ionisationRevision || null;
    const ogmRevision = item.ogmRevision || null;
    const nanoRevision = item.nanoRevision || null;
    const traceabilityRevision = item.traceabilityRevision || null;
    const fraudRevision = item.fraudRevision || null;
    const foodContactRevision = item.foodContactRevision || null;
    const sedex = item.sedex || null;
    const fda = item.fda || null;
    const ogm = item.ogm || null;
    const ionisation = item.ionisation || null;
    const nano = item.nano || null;
    const foodContact = item.foodContact || null;
    const haccpPrest = item.haccpPrest || null;
    const approval = item.approval || null;
    const codeOfConduct = item.codeOfConduct || null;
    const privateNotes = item.privateNotes || null;
    const group = item.group || null;

    const {
      type,
      name,
      mainContact,
      email,
      weekCrisisContact,
      weekEndCrisisContact,
      address,
      qualityContact,
      commercialContact,
      factory,
      certification,
      traceability,
      fraud,
      crisisManagement,
      claimsManagement,
      businessContinuity,
    } = item;
    const { firestore } = props;

    const docRef = yield firestore.collection('thirdParties')
      .doc(id);

    yield docRef.set({
      approval,
      haccpPrest,
      name,
      mainContact,
      comments,
      weekCrisisContact,
      weekEndCrisisContact,
      type,
      email,
      address,
      qualityContact,
      commercialContact,
      group,
      factory,
      certification,
      sedex,
      fda,
      nano,
      ogm,
      ionisation,
      traceability,
      fraud,
      foodContact,
      crisisManagement,
      claimsManagement,
      businessContinuity,
      codeOfConduct,
      ionisationRevision,
      ogmRevision,
      nanoRevision,
      traceabilityRevision,
      fraudRevision,
      foodContactRevision,
    }, { merge: true })
      .then(() => {
        props.firestore.collection('customers')
          .doc(customerId)
          .collection('thirdParties')
          .where('providerId', '==', id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach((doc) => {
              props.firestore.collection('customers')
                .doc(customerId)
                .collection('thirdParties')
                .doc(doc.id)
                .update({
                  privateNotes
                });
            });
          });
      });
    notification.success({
      message: 'Tiers mis à jour avec succès',
      description: `${name} est modifié`,
    });
    cb && cb();
  } catch (err) {
    console.log(err.toString());
    notification.error({
      message: 'Impossible de mettre le tiers',
      description: err.toString(),
    });
  }
}

/**
 * Update a provider status
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* updateThirdPartyStatus(action) {
  try {
    const {
      status, id, props: { firestore }
    } = action;
    const docRef = yield firestore.collection('thirdParties')
      .collection('thirdParties').doc(id);

    yield docRef.set({
      status,
    }, { merge: true });
    notification.success({
      message: 'Status mis à jour avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour le status',
      description: err.toString(),
    });
  }
}

/**
 * Delete a provider as superAdmin
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* deleteThirdPartyFromAdmin(action) {
  try {
    const { id, props: { firestore } } = action;
    yield firestore.collection('thirdParties')
      .doc(id)
      .delete();
    notification.success({
      message: 'Tiers supprimé avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer le groupe de diffusion',
      description: err.toString(),
    });
  }
}

/**
 * Delete a provider
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* deleteThirdParty(action) {
  try {
    const { id, props: { firestore, profile: { customerId } } } = action;
    yield firestore.collection('customers')
      .doc(customerId)
      .update({
        thirdParties: firestore.FieldValue.arrayRemove(id)
      }).then(() => {
        firestore.collection('customers')
          .doc(customerId)
          .collection('thirdParties')
          .where('providerId', '==', id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach((doc) => {
              firestore.collection('customers')
                .doc(customerId)
                .collection('thirdParties')
                .doc(doc.id)
                .delete();
            });
          });
      });
    notification.success({
      message: 'Tiers supprimé avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer le tiers',
      description: err.toString(),
    });
  }
}

/**
 * Update provider status
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* updateThirdPartiesActivationStatus(action) {
  try {
    const {
      status, props: { firestore, profile: { customerId } }
    } = action;
    const docRef = yield firestore.collection('customers').doc(customerId);

    yield docRef.set({
      thirdPartiesActivationStatus: status,
    }, { merge: true });
    notification.success({
      message: 'Status mis à jour avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour le status',
      description: err.toString(),
    });
  }
}

/**
 * Upload a provider document
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* documentUpload(action) {
  const {
    field, providerId, props, item, cb, matterId
  } = action;
  const {
    title, subTheme, type, document, versionDate
  } = item;
  const comments = item.comments || null;
  const internalCode = item.internalCode || null;

  const {
    firestore, firebase: { storage }, dispatch, profile: { customerId }
  } = props;

  const nameExtension = matterId !== 'null' ? '_matter_doc' : '_doc';

  try {
    const docRef = yield firestore.collection('providersDocuments')
      .doc();

    const storageRef = storage().ref('providersDocuments');
    const documentRef = storageRef.child(new Date().getTime() + document.file.name);
    const task = documentRef.put(document.file);
    monitorFileUpload(dispatch, task);

    const uploadProgress = ratio => Math.round(ratio * 100);

    task.on('state_changed', snapshot => {
      const progress = uploadProgress(
        snapshot.bytesTransferred / snapshot.totalBytes
      );
      if (snapshot && snapshot.state === 'running') {
        props.dispatch({
          type: Documents.DOCUMENT_PROVIDER_UPLOADING,
          payload: progress
        });
      }
    });
    yield task.then(() => {
      props.dispatch({
        type: Documents.DOCUMENT_PROVIDER_UPLOADED,
      });
      documentRef.getDownloadURL().then((downloadUrl) => {
        docRef.set({
          id: docRef.id,
          name: `${field}${nameExtension}`,
          date: new Date(),
          providerId,
          title,
          subTheme,
          type,
          comments,
          downloadUrl,
          validity: true,
          versionDate: versionDate.toDate(),
          humanVersionDate: versionDate.toDate().toLocaleString(),
          matterId,
        }).then(() => {
          documentRef.getMetadata().then(metadata => {
            docRef.set({
              documentName: metadata.name,
              timeCreated: metadata.timeCreated,
              update: metadata.updated,
            }, { merge: true });
          });
          if (internalCode) {
            props.firestore.collection('customers')
              .doc(customerId)
              .collection('providersDocuments')
              .doc()
              .set({
                docId: docRef.id,
                internalCode
              });
          }
        });
      });
    });
    cb && cb();
    notification.success({
      message: 'Document ajouté avec succès',
      description: `${title} est créé`,
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le document',
      description: err.toString(),
    });
  }
}

/**
 * Update a provider document
 * @param action
 * @returns {Generator<*, void, *>}
 */
function* documentUpdate(action) {
  const {
    id, documentName, props, item, cb
  } = action;

  const {
    title, subTheme, type, versionDate
  } = item;
  const document = item.document || null;
  const comments = item.comments || null;
  const internalCode = item.internalCode || null;

  const {
    firestore, firebase: { storage }, dispatch, profile: { customerId }
  } = props;

  const addInternalCode = (code) => {
    return props.firestore
      .collection('providersDocuments')
      .where('id', '==', id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          props.firestore.collection('customers')
            .doc(customerId)
            .collection('providersDocuments')
            .doc(doc.id)
            .set({
              docId: doc.id,
              internalCode
            }, { merge: true });
        });
      });
  };

  const showSuccessMessage = () => {
    notification.success({
      message: 'Document ajouté avec succès',
      description: `${title} est créé`,
    });
  };

  try {
    const docRef = yield firestore
      .collection('providersDocuments')
      .doc(id);

    if (document) {
      const storageRef = storage().ref('providersDocuments');
      const documentRef = storageRef.child(new Date().getTime() + document.file.name);
      const task = documentRef.put(document.file);

      monitorFileUpload(dispatch, task);
      const uploadProgress = ratio => Math.round(ratio * 100);

      task.on('state_changed', snapshot => {
        const progress = uploadProgress(
          snapshot.bytesTransferred / snapshot.totalBytes
        );
        if (snapshot && snapshot.state === 'running') {
          props.dispatch({
            type: Documents.DOCUMENT_PROVIDER_UPLOADING,
            payload: progress
          });
        }
      });

      yield task.then(() => {
        props.dispatch({
          type: Documents.DOCUMENT_PROVIDER_UPLOADED,
        });
        documentRef.getDownloadURL().then((downloadUrl) => {
          docRef.set({
            date: new Date(),
            title,
            subTheme,
            type,
            comments,
            downloadUrl,
            validity: true,
            versionDate: versionDate.toDate(),
            humanVersionDate: versionDate.toDate().toLocaleString()
          }, { merge: true }).then(() => {
            addInternalCode(internalCode);
            const pdfRefToDelete = storageRef.child(documentName);
            pdfRefToDelete.delete().then(() => {
              documentRef.getMetadata().then(metadata => {
                docRef.set({
                  documentName: metadata.name,
                  timeCreated: metadata.timeCreated,
                  update: metadata.updated,
                }, { merge: true });
              });
            });
          });
        });
      });
      cb && cb();
      showSuccessMessage();
    } else {
      docRef.set({
        title,
        subTheme,
        type,
        comments,
        validity: true,
        versionDate: versionDate.toDate(),
        humanVersionDate: versionDate.toDate().toLocaleString()
      }, { merge: true }).then(() => {
        addInternalCode(internalCode);
      });
      cb && cb();
      showSuccessMessage();
      props.dispatch({
        type: '@@reduxFirestore/LISTENER_RESPONSE',
        meta: {
          collection: 'internalCode',
        },
        payload: {
          data: {},
        },
      });
    }
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le document',
      description: err.toString(),
    });
  }
}

function* createMatter(action) {
  const {
    item, customerId, providerId, props, cb
  } = action;
  const { firestore, profile } = props;
  const origin = item.origin || null;
  const foodContact = item.foodContact || null;
  const allergens = item.allergens || null;
  const nutritionalValues = item.nutritionalValues || null;
  const ingredients = item.ingredients || null;

  const {
    type,
    name,
    haccp,
    contaminants,
    productionDiagram,
    requirements,
    technicalSheet,
    surveillancePlan,
    customsCode,
  } = item;

  const other = item?.other || null;

  try {
    const docRef = yield (
      firestore.collection('matters')
        .doc()
    );

    yield docRef.set({
      id: docRef.id,
      type,
      name,
      haccp,
      origin,
      foodContact,
      contaminants,
      allergens,
      nutritionalValues,
      productionDiagram,
      requirements,
      technicalSheet,
      ingredients,
      surveillancePlan,
      customsCode,
      other,
      providerId,
      customerId,
    }).then(() => {
      if (profile?.role !== 'supplier') {
        props.firestore.collection('customers')
          .doc(customerId)
          .update({
            matters: props.firestore.FieldValue.arrayUnion(docRef.id)
          });
        props.firestore.collection('customers')
          .doc(customerId)
          .collection('matters')
          .doc()
          .set({
            matterId: docRef.id,
          });
      }
    });
    notification.success({
      message: 'Matière ajoutée avec succès',
      description: `${name} est créé`,
    });
    cb && cb(docRef.id);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter la matière',
      description: err.toString(),
    });
  }
}

function* updateMatter(action) {
  const {
    id, item, props, cb
  } = action;
  const { firestore } = props;
  const origin = item.origin || null;
  const foodContact = item.foodContact || null;
  const allergens = item.allergens || null;
  const nutritionalValues = item.nutritionalValues || null;
  const ingredients = item.ingredients || null;

  const {
    type,
    name,
    haccp,
    contaminants,
    productionDiagram,
    requirements,
    technicalSheet,
    surveillancePlan,
    customsCode,
  } = item;

  const other = item?.other || null;

  try {
    const docRef = yield (
      firestore.collection('matters')
        .doc(id)
    );

    yield docRef.set({
      type,
      name,
      haccp,
      origin,
      foodContact,
      contaminants,
      allergens,
      nutritionalValues,
      productionDiagram,
      requirements,
      technicalSheet,
      ingredients,
      surveillancePlan,
      customsCode,
      other,
    }, { merge: true });
    notification.success({
      message: 'Matière modifiée avec succès',
      description: `${name} est créé`,
    });
    cb && cb(id);
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier la matière',
      description: err.toString(),
    });
  }
}

function* deleteMatter(action) {
  try {
    const { id, props: { firestore, profile: { customerId } } } = action;
    yield firestore.collection('customers')
      .doc(customerId)
      .update({
        matters: firestore.FieldValue.arrayRemove(id)
      }).then(() => {
        firestore.collection('customers')
          .doc(customerId)
          .collection('matters')
          .where('matterId', '==', id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach((doc) => {
              firestore.collection('customers')
                .doc(customerId)
                .collection('matters')
                .doc(doc.id)
                .delete();
            });
          });
      });
    notification.success({
      message: 'Matière supprimée avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer la matière',
      description: err.toString(),
    });
  }
}

function* exportDocuments(action) {
  try {
    const { selectedRows, props } = action;
    const {
      providersDocuments, subThemes, documentTypes, thirdParties, providerMatters
    } = props;
    const documentsToExport = providersDocuments.filter(doc => selectedRows.includes(doc.id));
    const fieldsToExport = [
      'humanVersionDate',
      'title',
      'providerId',
      'subTheme',
      'type',
      'downloadUrl',
      'validity',
      'matterId',
    ];
    yield new Exporter(
      fieldsToExport,
      'Liste des documents',
      subThemes,
      documentTypes,
      thirdParties,
      providerMatters,
    ).transformAndExportAsExcelFile(documentsToExport);
    notification.success({
      message: 'Export complété avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de procéder à l\'export',
      description: err.toString(),
    });
  }
}

function* addExistingThirdParty(action) {
  try {
    const { thirdPartyId, props } = action;
    const { profile: { customerId }, history: { replace } } = props;
    yield props.firestore.collection('customers')
      .doc(customerId)
      .update({
        thirdParties: props.firestore.FieldValue.arrayUnion(thirdPartyId)
      });
    yield props.firestore.collection('customers')
      .doc(customerId)
      .collection('thirdParties')
      .doc()
      .set({
        providerId: thirdPartyId,
      });
    notification.success({
      message: 'Tiers ajouté avec succès',
    });
    replace(`/company/${customerId}/thirdParty/${thirdPartyId}/details`);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le tiers',
      description: err.toString(),
    });
  }
}

function* addExistingThirdPartyMatter(action) {
  console.log('addExistingThirdPartyMatter', action);
  try {
    const { matterId, props } = action;
    const { profile: { customerId }, history: { replace }, thirdParties } = props;
    const thirdPartyId = thirdParties[0]?.id;
    const returnUrl = `/company/${customerId}/thirdParty/${thirdPartyId}/matter/${matterId}/details`;
    yield props.firestore.collection('customers')
      .doc(customerId)
      .update({
        matters: props.firestore.FieldValue.arrayUnion(matterId)
      });
    yield props.firestore.collection('customers')
      .doc(customerId)
      .collection('matters')
      .doc()
      .set({
        matterId,
      });
    notification.success({
      message: 'Tiers ajouté avec succès',
    });
    replace(returnUrl);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le tiers',
      description: err.toString(),
    });
  }
}

function* onDeleteDocument(action) {
  try {
    const {
      documentId, name, props
    } = action;
    const storageRef = props.firebase.storage().ref('providersDocuments');
    const pdfRef = storageRef.child(name);

    yield pdfRef.delete().then(() => {
      props.firestore.collection('providersDocuments').doc(documentId)
        .delete();
    });
    notification.success({
      message: 'Document supprimé avec succès',
      description: `${name} est supprimé`,
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer le document',
      description: err.toString(),
    });
  }
}

function* onEnhanceValidity(action) {
  const date = moment().add(1, 'years').toDate();
  try {
    const { id, props } = action;

    const docRef = yield props.firestore.collection('providersDocuments')
      .doc(id);
    yield docRef.update({
      versionDate: date,
      humanVersionDate: date.toLocaleString(),
      validity: true,
    });
    notification.success({
      message: 'Validité mise à jour avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de procéder à la modification de la validité',
      description: err.toString(),
    });
  }
}

export function* ThirdPartiesSaga() {
  yield takeLatest(ThirdParties.THIRD_PARTIES_ADDING, createThirdParty);
  yield takeLatest(ThirdParties.THIRD_PARTIES_UPDATE, updateThirdParty);
  yield takeLatest(ThirdParties.THIRD_PARTIES_STATUS_UPDATE, updateThirdPartyStatus);
  yield takeLatest(ThirdParties.THIRD_PARTIES_DELETE, deleteThirdParty);
  yield takeLatest(ThirdParties.THIRD_PARTIES_ACTIVATE_STATUS, updateThirdPartiesActivationStatus);
  yield takeLatest(ThirdParties.THIRD_PARTIES_DOCUMENT_UPLOAD, documentUpload);
  yield takeLatest(ThirdParties.THIRD_PARTIES_DOCUMENT_UPDATE, documentUpdate);
  yield takeLatest(ThirdParties.THIRD_PARTIES_MATTER_ADDED, createMatter);
  yield takeLatest(ThirdParties.THIRD_PARTIES_MATTER_UPDATE, updateMatter);
  yield takeLatest(ThirdParties.THIRD_PARTIES_MATTER_DELETE, deleteMatter);
  yield takeLatest(ThirdParties.THIRD_PARTIES_EXPORT_DOCUMENTS, exportDocuments);
  yield takeLatest(ThirdParties.THIRD_PARTIES_ADD_EXISTING, addExistingThirdParty);
  yield takeLatest(ThirdParties.THIRD_PARTIES_DELETE_DOCUMENT, onDeleteDocument);
  yield takeLatest(ThirdParties.THIRD_PARTIES_ENHANCE_VALIDITY, onEnhanceValidity);
  yield takeLatest(ThirdParties.THIRD_PARTIES_ADD_EXISTING_MATTER, addExistingThirdPartyMatter);
}
