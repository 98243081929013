export default [
  'cause',
  'checkDate',
  'correctiveAction',
  'correctiveActionDoneDate',
  'correctiveActionDueDate',
  'correctiveActionResponsible',
  'customerId',
  'delta',
  'detailOrigin',
  'immediateCorrectionDoneDate',
  'immediateCorrectionDueDate',
  'immediateCorrectionResponsible',
  'openDate',
  'origin',
  'severityIndex',
  'status',
  'cnq',
];
