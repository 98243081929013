const Documents = {
  DOCUMENT_ADDING: 'document/adding',
  DOCUMENT_DELETE: 'document/delete',
  DOCUMENT_UPLOADING: 'document/uploading',
  DOCUMENT_UPLOADED: 'document/uploaded',
  DOCUMENT_VISIBILITY_UPDATE: 'document/visibility_update',
  DOCUMENT_UPDATING: 'document/updating',
  DOCUMENT_PROVIDER_UPLOADING: 'document/providerUploading',
  DOCUMENT_PROVIDER_UPLOADED: 'document/providerUploaded',
  DOCUMENT_PROVIDER_CLEAR_INTERNAL_CODE: 'document/providerClearInternalCode',
};

export default Documents;
