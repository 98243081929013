import {takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import Origin from './constants';

function* onOriginItemAdding(action) {
  try {
    const { title, color } = action.item;
    const status = action.item.status || true;
    const { cb } = action;
    const docRef = yield (action.props.firestore.collection('origin').doc());

    yield docRef.set({
      title,
      status,
      color,
      id: docRef.id,
    });

    notification.success({
      message: 'Élement ajouté avec succès',
      description: `${title} est créé`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter l\'élément',
      description: err.toString(),
    });
  }
}

export function* onOriginItemUpdate(action) {
  try {
    const { title, color } = action.item;
    const status = action.item.status || true;
    const { cb } = action;
    const docRef = action.props.firestore.collection('origin').doc(action.id);
    yield docRef.set({
      title,
      status,
      color,
    }, { merge: true });
    notification.success({
      message: 'Élément avec succès',
      description: `${title} est modifié`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer l\'élément',
      description: err.toString(),
    });
  }
}

export function* onOriginItemStatusUpdate(action) {
  try {
    const { id, props, value } = action;
    const docRef = props.firestore.collection('origin').doc(id);
    yield docRef.set({
      status: value,
    }, { merge: true });
    notification.success({
      message: 'Status modifié avec succès',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'archiver le thème',
      description: err.toString(),
    });
  }
}

export function* OriginSaga() {
  yield takeLatest(Origin.ORIGIN_ITEM_ADDING, onOriginItemAdding);
  yield takeLatest(Origin.ORIGIN_ITEM_UPDATE, onOriginItemUpdate);
  yield takeLatest(Origin.ORIGIN_ITEM_STATUS_UPDATE, onOriginItemStatusUpdate);
}
