import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import _ from 'lodash';
import Exporter from './exports/Exporter';

import Actions from './constants';
import ExporterFields from './exports/ExporterFields';
import { monitorFileUpload } from '../../../services/documents';
import Documents from '../documents/constants';

function* onActionAdding(action) {
  try {
    const { title, subTheme, isRecurring } = action.item;
    const comments = action.item.comments || null;
    const {
      date, props, cb, customerId
    } = action;
    const user = props.firebase.auth().currentUser;
    const fromDate = action.item?.fromDate ? action.item.fromDate.toDate().toLocaleString() : null;
    const toDate = action.item?.toDate ? action.item.toDate.toDate().toLocaleString() : null;
    const step = action.item?.step || null;
    const pilotId = action.item?.pilotId || null;

    const docRef = yield props.firestore.collection('customers')
      .doc(customerId)
      .collection('actions').doc();

    yield docRef.set({
      title,
      subTheme,
      comments,
      date,
      customerId,
      status: false,
      id: docRef.id,
      step,
      recurring: isRecurring,
      fromDate,
      toDate,
      userId: user.uid,
      pilotId,
    });
    notification.success({
      message: 'Action ajoutée avec succès',
      description: `${title} est créé`,
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb(docRef.id);

    // eslint-disable-next-line no-unused-expressions
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter l\'action',
      description: err.toString(),
    });
  }
}

function* onSimpleActionAdding(action) {
  const {
    date, majorThemeId, cb, props, chosen, item
  } = action;
  const {
    title, type, subTheme, document
  } = item;
  try {
    const { profile: { customerId } } = props;
    const comments = action.item.comments || null;
    const isPublic = action.item.public || false;
    const visibility = action.item.public || false;
    const replace = action.item.replace || false;
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('documents').doc();
    const storageRef = props.firebase.storage().ref(`company/${customerId}`);
    const pdfRef = storageRef.child(new Date().getTime() + document.file.name);

    notification.success({
      message: 'Envoi du document en cours...',
      description: `${title} sera disponile dans quelques secondes`,
      duration: 5,
    });

    cb && cb();
    const task = pdfRef.put(document.file);
    monitorFileUpload(props.dispatch, task);
    const uploadProgress = ratio => Math.round(ratio * 100);

    task.on('state_changed', snapshot => {
      const progress = uploadProgress(
        snapshot.bytesTransferred / snapshot.totalBytes
      );
      if (snapshot && snapshot.state === 'running') {
        props.dispatch({
          type: Documents.DOCUMENT_UPLOADING,
          payload: progress
        });
      }
    });

    yield task.then(() => {
      props.dispatch({
        type: Documents.DOCUMENT_UPLOADED,
      });
      pdfRef.getDownloadURL().then((downloadUrl) => {
        docRef.set({
          title,
          type,
          comments,
          isPublic,
          visibility,
          hasReplaced: replace,
          date,
          themeId: subTheme,
          majorThemeId,
          customerId,
          downloadUrl,
        }).then(() => {
          pdfRef.getMetadata().then((metadata) => {
            docRef.set({
              name: metadata.name,
              contentType: metadata.contentType,
              fullPath: metadata.fullPath,
              timeCreated: metadata.timeCreated,
              update: metadata.updated,
            }, { merge: true });
          });
        });
      });
    });

    if (!_.isEmpty(chosen)) {
      chosen.forEach((doc) => {
        const pdfRefToDelete = storageRef.child(doc.name);
        pdfRefToDelete.delete().then(() => {
          props.firestore.collection('customers').doc(customerId).collection('documents').doc(doc.id)
            .delete()
            .then(res => console.log(res, 'res deleted'));
        }).catch(e => {
          console.log(e.toString());
        });
      });
    }
    notification.success({
      message: 'Document ajouté avec succès',
      description: `${title} est créé`,
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le document',
      description: err.toString(),
    });
  }
}

function* onUpdateRequest(action) {
  try {
    const { title, subTheme } = action.item;
    const comments = action.item.comments || null;
    const { cb, id, props } = action;

    const isRecurring = action.item?.recurring || false;
    const fromDate = action.item?.fromDate ? action.item.fromDate.toDate().toLocaleString() : null;
    const toDate = action.item?.toDate ? action.item.toDate.toDate().toLocaleString() : null;
    const step = action.item?.step || null;
    const pilotId = action.item?.pilotId || null;

    const { profile: { customerId } } = props;
    const docRef = props.firestore.collection('customers').doc(customerId).collection('actions').doc(id);

    yield docRef.set({
      title,
      subTheme,
      comments,
      step,
      isRecurring,
      fromDate,
      toDate,
      pilotId,
    }, { merge: true });

    yield props.firestore.collection('customers').doc(customerId).collection('documents')
      .where('actionId', '==', id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(doc => {
          props.firestore.collection('customers').doc(customerId).collection('documents').doc(doc.id)
            .set({
              themeId: subTheme,
            }, { merge: true });
        });
      });

    yield props.firestore.collection('customers').doc(customerId).collection('actionPlans')
      .where('actionId', '==', id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(plan => {
          props.firestore.collection('customers').doc(customerId).collection('actionPlans').doc(plan.id)
            .set({
              themeId: subTheme,
            }, { merge: true });
        });
      });

    notification.success({
      message: 'Action modifiée avec succès',
      description: `${title} est modifié`,
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer l\'action',
      description: err.toString(),
    });
  }
}

function* onDeleteRequest(action) {
  try {
    const { id, props, cb } = action;
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
    const { profile: { customerId } } = props;
    yield props.firestore.collection('customers').doc(customerId).collection('actions').doc(id)
      .delete();
    notification.success({
      message: 'Action supprimée avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de supprimer l\'action',
      description: err.toString(),
    });
  }
}

function* onActionTerminate(action) {
  try {
    const { id, props, cb } = action;
    const { profile: { customerId } } = props;
    const endDate = new Date();
    const docRef = props.firestore.collection('customers').doc(customerId).collection('actions').doc(id);
    yield docRef.set({
      status: true,
      endDate: endDate.toLocaleString(),
    }, { merge: true })
      .then(() => {
        props.firestore.collection('customers').doc(customerId).collection('actions').doc(id)
          .collection('actionPlans')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.set({
                status: true,
              }, { merge: true });
            });
          });
      });
    notification.success({
      message: 'Action terminée avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de terminer l\'action',
      description: err.toString(),
    });
  }
}

function* onActionReopen(action) {
  try {
    const { id, props, cb } = action;
    const { profile: { customerId } } = props;
    const docRef = props.firestore.collection('customers').doc(customerId).collection('actions').doc(id);
    yield docRef.set({
      status: false,
    }, { merge: true });
    notification.success({
      message: 'Action réouvert avec succès',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de réouvrir l\'action',
      description: err.toString(),
    });
  }
}

function* exportActions(action) {
  try {
    const { selectedRows, props } = action;
    const { dataSource, subThemes, actionPlans } = props;

    const actionsToExport = dataSource.filter(item => selectedRows.includes(item.id));
    const ationsWithPLan = actionsToExport.map(item => ({
      item,
      children: actionPlans.filter(plan => plan.actionId === item.id)
    }));

    const finalValues = [];
    ationsWithPLan.forEach(obj => {
      finalValues.push({
        ...obj.item,
      });
      const { children } = obj;
      if (!_.isEmpty(children)) {
        children.forEach(child => {
          finalValues.push({
            ...obj.item,
            a_title: child.title,
            a_comments: child.comments,
            a_date: child.date,
            a_dueDate: child.dueDate,
            a_status: child.status,
          });
        });
      }
    });
    yield new Exporter(
      ExporterFields,
      'Liste des actions',
      subThemes,
    ).transformAndExportAsExcelFile(finalValues);
    notification.success({
      message: 'Export complété avec succès',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de procéder à l\'export',
      description: err.toString(),
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* ActionSaga() {
  yield takeLatest(Actions.ACTION_ADDING, onActionAdding);
  yield takeLatest(Actions.ACTION_UPDATE, onUpdateRequest);
  yield takeLatest(Actions.ACTION_DELETE, onDeleteRequest);
  yield takeLatest(Actions.ACTION_TERMINATE, onActionTerminate);
  yield takeLatest(Actions.ACTION_REOPEN, onActionReopen);
  yield takeLatest(Actions.ACTION_DOEXPORT, exportActions);
  yield takeLatest(Actions.SIMPLE_ACTION_ADD, onSimpleActionAdding);
}
