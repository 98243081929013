import _ from 'lodash';
import ExporterSchema from './ExporterSchema';
import { Excel } from '../excel';
import { getOriginTitle } from '../../../services/origin';
import { getUsernameFromId } from '../../../services/users';
import { getProviderNameFromId } from '../../../services/ThirdParties';

export default class Exporter {
  constructor(fields, excelFileName, origine, users, thirdParties) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
    this.origines = origine;
    this.users = users;
    this.thirdParties = thirdParties;
  }

  formatHeaders =(row) => ({
    Date_ouverture: row.openDate,
    Origine: getOriginTitle(this.origines, row.origin),
    Details_origine: row.detailOrigin,
    Fournisseur: getProviderNameFromId(row.thirdParty, this.thirdParties),
    Numero_lot: row.batchNumber,
    Ecart: row.delta,
    Indice_de_gravite: row.severityIndex,
    Recherche_de_cause: row.cause,
    Correction_immediate: row.immediateCorrection,
    Responsable_correction_immediate: getUsernameFromId(this.users, row.immediateCorrectionResponsible),
    Echeance_correction_immediate: row.immediateCorrectionDueDate,
    Date_realisation_correction_immediate: row.immediateCorrectionDoneDate,
    Action_corrective: row.correctiveAction,
    Responsable_action_corrective: getUsernameFromId(this.users, row.correctiveActionResponsible),
    Echeance_action_corrective: row.correctiveActionDueDate,
    Date_realisation_action_corrective: row.correctiveActionDoneDate,
    Images: Exporter.formatImageField(row.images),
    CNQ: row.cnq
  })

  static formatImageField(row) {
    if (_.isEmpty(row) || !row) { return; }
    if (row.length === 1) {
      return row[0].publicUrl;
    }
    const a = _.chain(row)
      .map((image) => image.publicUrl)
      .join(', ')
      .value();
    return a;
  }

  transformAndExportAsExcelFile(rows) {
    const exportableData = rows.map((row) => this.formatHeaders(row)
    );

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      `${this.excelFileName} ${new Date().getTime()}`,
    );
  }
}
