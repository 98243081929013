import { takeLatest, all } from 'redux-saga/effects';
import { notification } from 'antd';
import _ from 'lodash';

import Form from './constants';

function* createActions(item, action) {
  const subTheme = item.theme;
  const { title } = item.form[0];
  const {
    props,
    date,
    customerId,
  } = action;

  try {
    const docRef = yield (props.firestore.collection('customers').doc(customerId).collection('actions').doc());

    yield docRef.set({
      title,
      subTheme,
      date,
      customerId,
      id: docRef.id,
      status: false,
      recurring: false,
    });
    notification.success({
      message: 'Action ajoutée avec succès',
      description: `${title} est créé`,
    });
    // cb && cb(docRef.id);
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter l\'action',
      description: err.toString(),
    });
  }
}

function* onFormSent(action) {
  const actionsToCreate = action.item;
  const { other } = action.item || null;

  if (other) {
    const { customerId, props, date } = action;
    try {
      const docRef = yield (props.firestore.collection('messages').doc());
      yield docRef.set({
        message: other,
        customerId,
        date,
        id: docRef.id,
        read: false,
      });
    } catch (err) {
      console.log(err.toString());
    }
  }
  if (!_.isEmpty(actionsToCreate[0])) {
    try {
      const { cb } = action;
      yield all(actionsToCreate.map(item => createActions(item, action)));
      cb && cb();
    } catch (err) {
      notification.error({
        message: 'Impossible d\'ajouter l\'action',
        description: err.toString(),
      });
    }
  } else {
    const {
      props,
      customerId,
    } = action;
    try {
      const docRef = action.props.firestore.collection('customers').doc(customerId);
      yield docRef.set({
        formItems: true,
      }, { merge: true });
      notification.success({
        message: 'Vos actions sont à jour',
      });
      props.history.push('/');
    } catch (err) {
      console.log(err.toString());
    }
  }
}

export function* CompanyFormSaga() {
  yield takeLatest(Form.ON_FORM_SENT, onFormSent);
}
