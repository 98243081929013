import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import Subscription from './constants';

function* onSubscriptionEdit(action) {
  try {
    const { subscriptions, customerId } = action;
    const docRef = yield action.props.firestore.collection('customers').doc(customerId);
    yield docRef.set({
      subscriptions,
    }, { merge: true });
    notification.success({
      message: 'Souscriptions modifiéés avec succès',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer la liste des souscriptions',
      description: err.toString(),
    });
  }
}

export function* SubscriptionSaga() {
  yield takeLatest(Subscription.EDIT_SUBSCRIPTION, onSubscriptionEdit);
}
