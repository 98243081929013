import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import Atmosphere from './constants';

function* onUpdateAtmosphere(action) {
  try {
    const { item, props, cb } = action;
    yield props.firebase.updateProfile({ color: item.color });
    notification.success({
      message: 'Ambiance modifié avec succès',
      description: '',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (e) {
    notification.error({
      message: 'Impossible de modifier l\'ambiance',
      description: e.toString(),
    });
    throw new Error(e);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* AtmosphereSaga() {
  yield takeLatest(Atmosphere.ATMOSPHERE_UPDATE, onUpdateAtmosphere);
}
