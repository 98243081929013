import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import Accounts from './constants';

function* onUpdateUserProfile(action) {
  try {
    const { username } = action.item;
    const { cb, props } = action;
    yield props.firebase.updateProfile({ username });
    notification.success({
      message: 'Utilisateur modifié avec succès',
      description: '',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier l\'utilisateur',
      description: err.toString(),
    });
  }
}

function* onUpdateUserEmail(action) {
  try {
    const { email } = action.item;
    const { cb, props } = action;
    yield props.firebase.updateEmail(email).then(() => {
      props.firebase.updateProfile({ email });
    });
    notification.success({
      message: 'Email utilisateur modifié avec succès',
      description: '',
    });
    // eslint-disable-next-line no-unused-expressions
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible de modifier l\'email de l\'utilisateur',
      description: err.toString(),
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* AccountSaga() {
  yield takeLatest(Accounts.ACCOUNT_UPDATE_PROFILE, onUpdateUserProfile);
  yield takeLatest(Accounts.ACCOUNT_UPDATE_EMAIL, onUpdateUserEmail);
}
