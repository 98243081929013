import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import Alerts from './constants';

function* onAlertRead(action) {
  const { id, props } = action;
  const { profile: { customerId } } = props;

  try {
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('alerts').doc(id);
    yield docRef.set({
      read: true,
    }, { merge: true });
    notification.success({
      message: 'Alerte lue',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour l\'alert',
      description: err.toString(),
    });
  }
}

function* onAlertDone(action) {
  const { id, props } = action;
  const { profile: { customerId } } = props;

  try {
    const docRef = yield props.firestore.collection('customers').doc(customerId).collection('alerts').doc(id);
    yield docRef.set({
      done: true,
    }, { merge: true });
    notification.success({
      message: 'Alerte lue',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible de mettre à jour l\'alert',
      description: err.toString(),
    });
  }
}

export function* AlertsSaga() {
  yield takeLatest(Alerts.ALERT_READ, onAlertRead);
  yield takeLatest(Alerts.ALERT_DONE, onAlertDone);
}
