/**
 * Returns the name of a matter identified by its ID.
 *
 * @param {number} matterId - The ID of the matter to get the name for.
 * @param {Array} providerMatters - The array of matters to search within.
 * @param {Function} getId - A function that accepts a matter and returns its ID.
 * @param {Function} getName - A function that accepts a matter and returns its name.
 * @returns {string|undefined} - The name of the matter, or undefined if not found.
 */
const getMatterNameById = (matterId, providerMatters, getId, getName) => {
  const matchedMatter = providerMatters.find(matter => getId(matter) === matterId);
  return matchedMatter ? getName(matchedMatter) : undefined;
};

/**
 * Returns a filtered array of matters based on the given name.
 *
 * @param {string} name - The name of the matter to filter by.
 * @param {Array<Object>} providerMatters - An array of provider matters to filter.
 * @param {Function} isMatchingMatterName - A callback function to determine if a matter name matches the given name.
 * @returns {Array<Object>} - The filtered array of matters.
 */
const getMattersByName = (
  name, providerMatters, isMatchingMatterName
) => providerMatters.filter(
  matter => isMatchingMatterName(matter, name)
);

export {
  getMatterNameById,
  getMattersByName,
};
