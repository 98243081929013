const Themes = {
  FETCH_ALL: 'themes/fetch_all',
  FETCH_LOADING: 'themes/loading',
  FETCH_ERROR: 'themes/fetch_error',
  FETCH_SUCCESS: 'themes/fetch_success',
  ADDING_THEME: 'theme/add',
  ADD_THEME_SUCCESS: 'theme/add_success',
  ADD_THEME_ERROR: 'theme/add_error',
  THEME_DETAIL_REQUEST: 'theme/detail_request',
  THEME_UPDATE_REQUEST: 'theme/update_request',
  THEME_DETAIL_SUCCESS: 'theme/details_success',
  THEME_DETAIL_FAILURE: 'theme/details_failed',
  THEME_DELETE_REQUEST: 'theme/delete_request',
  THEME_ARCHIVE_ACTION: 'theme/archive_action',
};

export default Themes;
