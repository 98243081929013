const getSubThemeTitle = (subThemes, id) => {
  if (!id) return '';
  const subTheme = subThemes.find(item => item.id === id);
  if (!subTheme) return '';
  return subTheme.title;
};

const getFilteredThemes = (subThemes, themes) => {
  const ids = subThemes.reduce((a, b) => {
    a.push(b.parentId);
    return a;
  }, []);
  return themes.filter(theme => ids.includes(theme.id));
};

const getSubThemesTreeData = (themes, subThemes) => {
  const filteredThemes = getFilteredThemes(subThemes, themes);
  return filteredThemes.map(theme => (
    {
      title: theme.title,
      value: `toplevel${theme.id}`,
      key: theme.id,
      children: subThemes.reduce((acc, obj) => {
        if (obj.parentId === theme.id) {
          acc.push({
            title: obj.title,
            value: obj.id,
            key: obj.id,
            children: subThemes.reduce((a, b) => {
              if (b.parentId === obj.id) {
                a.push({ title: b.title, value: b.id, key: b.id });
              }
              return a;
            }, []
            )
          });
        }
        return acc;
      }, [])
    })
  );
};

export {
  getSubThemeTitle,
  getSubThemesTreeData,
};
