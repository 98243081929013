import { takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import Customers from './constants';

function* onCustomerAdding(action) {
  try {
    const { name } = action.item;
    const phone = action.item.phone || null;
    const city = action.item.city || null;
    const status = action.item.status || true;
    const information = action.item.information || null;
    const { cb } = action;
    const docRef = yield (action.props.firestore.collection('customers').doc());

    yield docRef.set({
      name,
      phone,
      city,
      status,
      information,
      setUp: false,
      formItems: false,
      id: docRef.id,
    });

    notification.success({
      message: 'Client ajouté avec succès',
      description: `${name} est créé`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'ajouter le client',
      description: err.toString(),
    });
  }
}

export function* onUpdateRequest(action) {
  try {
    const { name } = action.item;
    const phone = action.item.phone || null;
    const city = action.item.city || null;
    const status = action.item.status || true;
    const information = action.item.information || null;
    const { cb } = action;
    const docRef = action.props.firestore.collection('customers').doc(action.id);
    yield docRef.set({
      name,
      phone,
      city,
      status,
      information,
    }, { merge: true });
    notification.success({
      message: 'Client modifié avec succès',
      description: `${name} est modifié`,
    });
    cb && cb();
  } catch (err) {
    notification.error({
      message: 'Impossible d\'éditer le client',
      description: err.toString(),
    });
  }
}

export function* onCustomerArchiveStatusUpdate(action) {
  try {
    const { id, props, value } = action;
    const docRef = props.firestore.collection('customers').doc(id);
    yield docRef.set({
      status: value,
    }, { merge: true });
    notification.success({
      message: 'Status modifié avec succès',
      description: '',
    });
  } catch (err) {
    notification.error({
      message: 'Impossible d\'archiver le client',
      description: err.toString(),
    });
  }
}

export function* CustomerSaga() {
  yield takeLatest(Customers.ADDING_CUSTOMER, onCustomerAdding);
  yield takeLatest(Customers.CUSTOMER_UPDATE_SUCCESS, onUpdateRequest);
  yield takeLatest(Customers.CUSTOMER_ARCHIVE_ACTION, onCustomerArchiveStatusUpdate);
}
